'use strict'

import * as config_widget_development from '../js/configs/config-widget-development.js';
import * as config_benefitter_operator from '../js/configs/config-benefitter-operator.js';
import * as config_airdo_uat  from '../js/configs/config-airdo-uat.js';
import * as config_airdo  from '../js/configs/config-airdo.js';
import * as config_misumi_faq  from '../js/configs/config-misumi-faq.js';
import * as config_misumi_stg  from '../js/configs/config-misumi-stg.js';
import * as config_trial09  from '../js/configs/config-trial09.js';
import * as config_trial20  from '../js/configs/config-trial20.js';
import * as config_brownie_stg  from '../js/configs/config-brownie-stg.js';
import * as config_gui_dev from '../js/configs/config-gui-dev.js';

const config = config_benefitter_operator;

export function run(){
  let conf  = {};
  let theme = {};
  let tmpl  = {};

  conf  = config.load(conf);
  theme = conf.theme;

  conf  = theme.define_conf(conf);
  tmpl  = theme.define_view(conf);

  return { 'conf':conf, 'theme':theme, 'tmpl':tmpl };
}
