'use strict'

export function generate_uuid() {
  var uuid_string;
  // uid set
  var chatwidget_uuid = localStorage.getItem('chatwidget_uuid')
  if (chatwidget_uuid === undefined || chatwidget_uuid === null) {

    let chars = "CW-xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx".split("");
    for (let i = 0, len = chars.length; i < len; i++) {
        switch (chars[i]) {
            case "x":
                chars[i] = Math.floor(Math.random() * 16).toString(16);
                break;
            case "y":
                chars[i] = (Math.floor(Math.random() * 4) + 8).toString(16);
                break;
        }
    }

    uuid_string = chars.join("")
    localStorage.setItem('chatwidget_uuid', uuid_string)
  } else {
    uuid_string = chatwidget_uuid;
  }
  console.debug(`uid: ${uuid_string}`)
  return uuid_string;
}

export function set_serverside_cookie(params_json){
  const conf = params_json.config;

  // set server side cookie
  var url = `${conf.benefitter_origin}/webchat`

  fetch(url, {
    method: 'GET',
    mode: 'cors',
    credentials: 'include',
  })
  .then(response => response.text())
  .then(textBody => {
    if (conf.isDebug === true) { console.debug('accessed: /index') }
  })
}

export function get_common_master_settings(params_json) {
  const conf = params_json.config;
  const benefitter_origin = conf.benefitter_origin;

  return new Promise(resolve => {
    if (conf.isDebug === true) { console.debug('get_common_master: /settings') }

    //common_master_settings
    fetch(benefitter_origin+'/webchat/get_common_master_settings', {
      method: 'get',
      mode: 'cors',
      headers: {
        'X-Bro-Signature': 'true'
      },
    })
    .then(response => response.json())
    .then(jsonBody => {
      resolve(jsonBody.data);
    })
  })
}


export function decorate_text(params_json) {
  let text = params_json.text;
  let tmpl = params_json.template;

  text = $('<div></div>').html(text).text();

  text = text.replace(/\n/g, '<br>');

  // テキスト修飾：水平罫線（hr）表示
  var deco_list = text.match(/\$\(hr/g);

  // 水平罫線表示に一致するデータがある場合
  if (deco_list != null) {
    var offset = 0;
    for (var i = 0; i < deco_list.length; i++) {
      var search_str = deco_list[i];
      var style_str = "";
      var deco_index = text.substring(offset).indexOf(deco_list[i]);

      // "$(hr" に一致
      if (deco_index != -1) {
        var first_index = text.substring(offset+ deco_index, offset + deco_index + search_str.length).indexOf(search_str);

        // "$(hr" に一致
        if (first_index != -1) {
          first_index = first_index + deco_index + offset;
          var end_index = text.substring(first_index).indexOf(')');

          // "$(hr...)" に一致
          if (end_index != -1) {
            end_index = end_index + first_index;

            // "$(hr...)" 変換前
            var custom_str = text.substring(first_index, end_index+1);

            var tag_str = custom_str.replace("$(hr", "");
            tag_str = tag_str.replace(")", "");
            tag_str = tag_str.replace("[", "");
            tag_str = tag_str.replace("]", "");

            style_str = 'margin-top: 0; margin-bottom: 0;';
            if (tag_str.length > 0) {
              style_str = style_str + 'border-width: 1px 0px 0px 0px;';
              style_str = style_str + 'border-style: solid;';
              style_str = style_str + 'border-color: ' + tag_str + ';';
              style_str = style_str + 'height: 1px;';
            }

            var replace = '<hr style="' + style_str + '">';

            text = text.replace(custom_str, replace);

            // 次のパース開始位置
            offset = end_index + 1 + replace.length - custom_str.length;
          }
        }
      }
    }
  }

  // テキスト強調表示
  var deco_list = text.match(/\$\(/g);

  // テキスト強調表示に一致するデータがある場合
  if (deco_list != null) {
    var offset = 0;
    for (var i = 0; i < deco_list.length; i++) {
      var search_str = deco_list[i];
      var style_str = "";
      var deco_index = text.substring(offset).indexOf(deco_list[i]);

      // "$(" に一致
      if (deco_index != -1) {
        var first_index = text.substring(offset+ deco_index, offset + deco_index + search_str.length).indexOf(search_str);

        // "$(" に一致
        if (first_index != -1) {
          first_index = first_index + deco_index + offset;
          var end_index = text.substring(first_index).indexOf('}}');

          // "$(...)|{{...}}" に一致
          if (end_index != -1) {
            end_index = end_index + first_index;

            // "$(...)|{{...}}" 変換前
            var custom_str = text.substring(first_index, (end_index + 2));
            var tags = custom_str.split("|")[0];

            // bold
            if (tags.match(/bold/)) {
              style_str = style_str + 'font-weight:bold;';
            }

            // color
            if (tags.match(/color/)) {
              var tag_search_str = "color";
              var tag_index = tags.substring(0).indexOf("color");

              // "color" に一致
              if (tag_index != -1) {
                var tag_first_index = tag_index;
                var tag_end_index = tags.substring(tag_first_index).indexOf(']');

                // "color[...]" に一致
                if (tag_end_index != -1) {
                  tag_end_index = tag_end_index + tag_first_index;

                  // "color[...]" 変換前
                  var tag_str = tags.substring(tag_first_index, (tag_end_index + 1));
                  var attribute_str = tag_str.replace("color[", "");
                  attribute_str = attribute_str.replace("]", "");
                }
              }
              style_str = style_str + 'color:' + attribute_str + ';';
            }

            // background
            if (tags.match(/background/)) {
              var tag_search_str = "background";
              var tag_index = tags.substring(0).indexOf("background");

              // "background" に一致
              if (tag_index != -1) {
                var tag_first_index = tag_index;
                var tag_end_index = tags.substring(tag_first_index).indexOf(']');

                // "background[...]" に一致
                if (tag_end_index != -1) {
                  tag_end_index = tag_end_index + tag_first_index;

                  // "background[...]" 変換前
                  var tag_str = tags.substring(tag_first_index, (tag_end_index + 1));
                  var attribute_str = tag_str.replace("background[", "");
                  attribute_str = attribute_str.replace("]", "");
                }
              }
              style_str = style_str + 'background-color:' + attribute_str + ';';
            }

            // size
            if (tags.match(/size/)) {
              var tag_search_str = "size";
              var tag_index = tags.substring(0).indexOf("size");

              // "size" に一致
              if (tag_index != -1) {
                var tag_first_index = tag_index;
                var tag_end_index = tags.substring(tag_first_index).indexOf(']');

                // "size[...]" に一致
                if (tag_end_index != -1) {
                  tag_end_index = tag_end_index + tag_first_index;

                  // "size[...]" 変換前
                  var tag_str = tags.substring(tag_first_index, (tag_end_index + 1));
                  var attribute_str = tag_str.replace("size[", "");
                  attribute_str = attribute_str.replace("]", "");
                }
              }
              style_str = style_str + 'font-size:' + attribute_str + ';';
            }

            var disp_text = custom_str.split("|")[1];
            disp_text = disp_text.replace("{{", "");
            disp_text = disp_text.replace("}}", "");

            var replace = '<span style="' + style_str + '">' + disp_text + '</span>';

            text = text.replace(custom_str, replace);

            // 次のパース開始位置
            offset = end_index + 1 + replace.length - custom_str.length;
          }
        }
      }
    }
  }

  // url形式
  //var url_list = text.match(/https?:\/{2}(?:(?:[A-Za-z0-9-._~]|%[A-Fa-f0-9]{2})+(?::([A-Za-z0-9-._~]?|[%][A-Fa-f0-9]{2})+)?@)?(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.){1,126}[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?(?::[0-9]+)?(?:\/(?:[A-Za-z0-9-._~]|%[A-Fa-f0-9]{2})*)*(?:\?(?:[A-Za-z0-9-._~]+(?:=(?:[A-Za-z0-9-._~+]|%[A-Fa-f0-9]{2})+)?)(?:(?:&|;)[A-Za-z0-9-._~]+(?:=(?:[A-Za-z0-9-._~+]|%[A-Fa-f0-9]{2})+)?)*)?/g);
  var url_list = text.match(/((https?:\/\/[a-zA-Z0-9.\-_@:/~?%&;=+#',()*!]+))/g);

  // 1：カスタムurl "url|{...}"の画像をモーダル表示 ※image_link_modal_flg == trueの場合のみ
  // 2：カスタムurl "url|{...}"のリンク表示
  // 3：ノーマルurlの画像をモーダル表示 ※image_link_modal_flg == trueの場合のみ
  // 4：ノーマルurlのリンク表示

  // url形式に一致するデータがある場合
  if (url_list != null) {
    var offset = 0;
    var link_new_window_flg = $('#'+tmpl.send_msg_text_id).data('link-new-window-flg');
    var image_link_modal_flg = $('#'+tmpl.send_msg_text_id).data('image-link-modal-flg');

    for (var i = 0; i < url_list.length; i++) {
      // urlの数だけ繰り返し
      var custom_flg = false;
      var search_str = url_list[i]+"|{";
      var url_index = text.substring(offset).indexOf(url_list[i]);

      // "url" に一致
      if (url_index != -1) {
        var first_index = text.substring(offset+ url_index, offset + url_index + search_str.length).indexOf(search_str);
        if (/(jpg|jpeg|gif|png|bmp)$/.test(url_list[i])){
          var img_extension = true;
        }else{
          var img_extension = false;
        }

        // "url|{" に一致
        if (first_index != -1) {
          first_index = first_index + url_index + offset;
          var end_index = text.substring(first_index).indexOf('}');

          // "url|{...}" に一致
          if (end_index != -1) {
            end_index = end_index + first_index;

            // "url|{...}" 変換前
            var custom_link = text.substring(first_index, end_index+1);
            var link = custom_link.split("|")[0];
            var disp_text = custom_link.split("|")[1].substring(1, custom_link.split("|")[1].length -1);

            if (img_extension == true && image_link_modal_flg == true){
              var bef_text, aft_text;

              // 1：カスタムurl "url|{...}"の画像をモーダル表示
              bef_text = text.substring(0, first_index);
              aft_text = text.substring(end_index + 1);  
              var replace = getImageModal(url_list[i], disp_text, i);
              text = bef_text + replace + aft_text;
            }else{
              // 2：カスタムurl "url|{...}"のリンク表示
              // 汎用マスタの設定により リンクを必ず新規windowで開くかどうか制御
              if (link_new_window_flg == true) {
                var replace = '<a class="openWindowButton" href="javascript:void(0)" data-img-id="img' + i + '" data-link="' + link + '">' + disp_text + '</a>';
                // リンク先のサイズ取得に使用
                if (img_extension == true){
                  replace = replace + '<img src=' + link + ' style="display:none" id="img' + i + '">';
                }
              } else {
                var replace = '<a href="' + link + '" target="_blank">' + disp_text + '</a>';
              }
              text = text.replace(custom_link, replace);
            }

            // 次のurlパース開始位置
            offset = end_index + 1 + replace.length - custom_link.length;
            custom_flg = true;

          }
        }else{
        if (img_extension == true && image_link_modal_flg == true){
            // 3：ノーマルurlの画像をモーダル表示
            var first_index = text.substring(offset).indexOf(url_list[i]) + offset;
            var end_index = first_index + url_list[i].length;
            var bef_text, aft_text;

            bef_text = text.substring(0, first_index);
            aft_text = text.substring(end_index);

            var replace = getImageModal(url_list[i], url_list[i], i);
            text = bef_text + replace + aft_text;
            offset = end_index + 1 + replace.length - url_list[i].length;
            custom_flg = true;

          }
        }
        if (custom_flg == false) {
          // 4：ノーマルurlのリンク表示
          var first_index = text.substring(offset).indexOf(url_list[i]) + offset;
          var end_index = first_index + url_list[i].length;
          var bef_text, aft_text;

          bef_text = text.substring(0, first_index);
          aft_text = text.substring(end_index);
          
          // 汎用マスタの設定により リンクを必ず新規windowで開くかどうか制御
          if (link_new_window_flg == true) {
            var replace = '<a class="openWindowButton" href="javascript:void(0)" data-img-id="img' + i + '">' + url_list[i] + '</a>';
            // リンク先のサイズ取得に使用
            if (img_extension == true){
              replace = replace + '<img src=' + url_list[i] + ' style="display:none" id="img' + i + '">';
            }
          } else {
            var replace = '<a href="' + url_list[i] + '" target="_blank">' + url_list[i] + '</a>';
          }

          text = bef_text + replace + aft_text;
          offset = end_index + 1 + replace.length - url_list[i].length;
        }
      }
    }
  }

  // file形式
  // 汎用マスタの設定により ファイルリンクを有効にするか制御
  if ($('#'+tmpl.send_msg_text_id).data('file_link_enabled_flg')) {

    // \u30a0-\u30ff：カタカナ
    // \u3040-\u309f：ひらがな
    // \u3005-\u3006：々, 〆（記号）
    // \u30e0-\u9fcf：CJK統合漢字
    var file_list = text.match(/((file:|\\\\)[a-zA-Z0-9Ａ-Ｚａ-ｚ０-９.\\\-_@:/~?%&;=+#',()*!\u30a0-\u30ff\u3040-\u309f\u3005-\u3006\u30e0-\u9fcf]+)/g);

    // file形式に一致するデータがある場合
    if (file_list != null) {
      var offset = 0;

      for (var i = 0; i < file_list.length; i++) {
        // fileの数だけ繰り返し
        var search_str = file_list[i]+"|{";
        var file_index = text.substring(offset).indexOf(file_list[i]);

        // "file" に一致
        if (file_index != -1) {
          var first_index = text.substring(offset+ file_index, offset + file_index + search_str.length).indexOf(search_str);

          // "file|{" に一致
          if (first_index != -1) {
            first_index = first_index + file_index + offset;
            var end_index = text.substring(first_index).indexOf('}');

            // "file|{...}" に一致
            if (end_index != -1) {
              end_index = end_index + first_index;

              // "file|{...}" 変換前
              var custom_link = text.substring(first_index, end_index+1);
              var link = custom_link.split("|")[0];

              // file:にマッチした場合の処理
              if ( link.match(/file:/)) {
                link = link.replace('file:','file:///');

              // file:にマッチしなかった場合の処理
              } else {
                link = "file:///" + link;
              }
              var disp_text = custom_link.split("|")[1].substring(1, custom_link.split("|")[1].length -1);

              // カスタムfile "file|{...}"のリンク表示
              var replace = '<a href="' + link + '" target="_blank">' + disp_text + '</a>';
              text = text.replace(custom_link, replace);

              // 次のfileパース開始位置
              offset = end_index + 1 + replace.length - custom_link.length;
              custom_flg = true;

            }
          } else {
            // ノーマルfileのリンク表示
            var first_index = text.substring(offset).indexOf(file_list[i]) + offset;
            var end_index = first_index + file_list[i].length;
            var text_link;

            bef_text = text.substring(0, first_index);
            aft_text = text.substring(end_index);
            
            // file:にマッチした場合の処理
            if ( file_list[i].match(/file:/)) {
              text_link = file_list[i].replace('file:','file:///');

            // file:にマッチしなかった場合の処理
            } else {
              text_link = "file:///" + file_list[i];
            }
            var replace = '<a href="' + text_link + '" target="_blank">' + file_list[i] + '</a>';

            text = bef_text + replace + aft_text;
            offset = end_index + 1 + replace.length - file_list[i].length;
          }
        } 
      }
    }
  }

  // maillink
  var maillink_list = text.match(/(mailto:.+?\|\{.+?\})/g);
  if (maillink_list != null) {
    var temporary_text = text;
    
    if (isDebug === true) { console.debug(temporary_text); }
    for (var i = 0; i < maillink_list.length; i++) {
      var link_tag = maillink_list[i].replace(/^(.+)\|\{(.+)\}$/g, '<a href="$1">$2</a>');
      link_tag = link_tag.replace(/<br>/g, '%0d%0a')
      temporary_text = temporary_text.replace(maillink_list[i], link_tag);
    }
    
    text = temporary_text;
  }

  return text;
}
