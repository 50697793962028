'use strict'

import * as theme from '../../theme/default-add-home-button/theme.js';

export function load(conf){

  conf.project     = 'benefitter-operator';
  conf.environment = 'production';

  conf.default_load_ui = 'widget';

  switch (conf.environment){

    case 'production':
      conf.isDebug = true;
      conf.isDevelop = false;
      conf.benefitter_origin    = 'https://benefitter-operator.brownie-chat.com';
      conf.widget_root          = `${conf.benefitter_origin}/benefitter-chat-widget`;
      conf.benefitter_app_token = 'OWViNDZlZTVlZWE5ODIxZTQ5MTJiMzc4NGNlMzI0MGVkZjg4ODI1ZWRlZDQ4ZWNmYTk4MjQ1NWMwMjI3M2E0Zg==';
      conf.benefitter_app_id    = '69d62129-8045-40b0-8e4f-275ae65d52a7'; 
      break;

    case 'staging':
      conf.isDebug = true;
      conf.isDevelop = false;
      conf.benefitter_origin    = 'https://brownie-stg.brownie-chat.com';
      conf.widget_root          = `${conf.benefitter_origin}/benefitter-chat-widget`;
      conf.benefitter_app_token = 'ZWU4ZjE4NTBhZWQxNmI0ZDUyOGEyYjc3ZDg0MGY1Y2Q5MTQwNzU2NDYyN2U2Yjk0Mjc0ODNjY2M2ODJhNmViNA==';
      conf.benefitter_app_id    = 'cd13e2d2-415b-4494-9bd2-10f2922e199f';
      break;

    case 'development':
    default:
      conf.isDebug = true;
      conf.isDevelop = true;
      conf.benefitter_origin    = 'http://ec2-176-34-15-164.ap-northeast-1.compute.amazonaws.com:3043';
      conf.widget_root          = `/benefitter-chat-widget`;
      conf.benefitter_app_token = 'OWViNDZlZTVlZWE5ODIxZTQ5MTJiMzc4NGNlMzI0MGVkZjg4ODI1ZWRlZDQ4ZWNmYTk4MjQ1NWMwMjI3M2E0Zg==';
      conf.benefitter_app_id    = '69d62129-8045-40b0-8e4f-275ae65d52a7';     
      break;
  }

  conf.chatwidget_plugin_css_carousel_style_url = `${conf.widget_root}/res/webchat-carousel-style/css/webchat-carousel.css`
  conf.chatwidget_plugin_css_carousel_url       = `${conf.widget_root}/res/bootstrap-carousel/css/bootstrap-carousel.css`
  conf.chatwidget_plugin_js_carousel_url        = `${conf.widget_root}/res/bootstrap-carousel/js/bootstrap.js`
  conf.chatwidget_plugin_css_glyphicon_url      = `${conf.widget_root}/res/bootstrap-glyphicon/css/bootstrap3-glyphicons.css`
  conf.jquery_ui_url                            = `${conf.widget_root}/res/jquery-ui/jquery-ui.min.js`
  conf.theme                                    = theme;

  return conf;
}
