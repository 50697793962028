'use strict'

export function GetQueryString() {
  // ***** parse url parameter
  if (1 < document.location.search.length) {
    var query = document.location.search.substring(1);
    var parameters = query.split('&');
    var result = new Object();
    for (var i = 0; i < parameters.length; i++) {
      var element = parameters[i].split('=');
      var paramName = decodeURIComponent(element[0]);
      var paramValue = decodeURIComponent(element[1]);
      result[paramName] = decodeURIComponent(paramValue);
    }
    return result;
  }
  return null;
}

export function logger_debug(conf, o){
  if (conf.isDebug === true) { console.debug(o) }
  return true;
}
export function logger_info(conf, o){
  console.log(o)
  return true;
}



export function isPresent(o){
  return (o !== undefined && o !== null);
}
