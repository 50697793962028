'use strict'

export function define_conf(conf) {
  // conf.chatwidget_js_url = `${conf.widget_root}/theme/misumi-faq/javascripts/brownie/webchat/bs-chat-bot.js`
  conf.chatwidget_skin_url = `${conf.widget_root}/theme/misumi-faq/stylesheets/brownie/webchat/bs-chat-bot.css`
  conf.chatwidget_header_icon_url = `${conf.widget_root}/theme/misumi-faq/images/brownie/webchat/bot-icon.png`

  conf.chatwidget_eyecatch_url = `${conf.widget_root}/theme/misumi-faq/images/brownie/webchat/kochira.png`
  conf.chatwidget_bot_icon_url = `${conf.widget_root}/theme/misumi-faq/images/brownie/webchat/bot-01.png`

  return conf;
}

export function define_view(conf) {
  let template = {};

  template.theme_name = 'misumi-faq'

  template.chatwidget_eyecatch_id = 'bs-chatbot-kicker'
  template.chatwidget_eyecatch = `
    <div id="${template.chatwidget_eyecatch_id}" style="display:none">
      <img src="${conf.chatwidget_eyecatch_url}">
    </div>
    `;
  
  template.container_id = 'bs-chatbot-system'
  template.container = `
    <div id="${template.container_id}" style="display:none;">
    </div>
    `;

  // widget header definition

  template.header_id = 'bs-header-area'
  template.webchat_close_button_id = 'bs-icon-close-button'
  template.webchat_home_button_id = 'webchat-home-button' // no use
  template.header_title_id = 'header_title'
  template.header_description_id = 'header_description' // no use
  template.header_icon_id = 'header_icon'

  template.header = `
    <div id="${template.header_id}">
      <div id="bs-h-area-left">
        <img id="${template.header_icon_id}" src="${conf.chatwidget_header_icon_url}">
        <h1 id="${template.header_title_id}">会員登録のお問合せを承ります</h1>
      </div>
      <div id="bs-h-area-right">
        <!--a href="">info</a-->
        <!--a href="">Help</a-->
        
        <div id="${template.webchat_close_button_id}">
          <a href="#" id="bs-icon-close"></a>
        </div>
      </div>
    <!-- End of header area -->
    </div>
    `;

  // widget body definition

  template.selector_date_disp = 'div.start-block';
  template.selector_date_disp_inner = '> p';
  
  template.selector_chatwidget_body = '#bs-chatbot-base'
  template.selector_chatwidget_transaction = '#bs-scroll-area'

  template.class_bot_message = 'bot-block'
  template.class_user_message = 'user-block'

  template.class_balloon = 'chatwidget-balloon'
  template.class_bot_balloon = `${template.class_bot_message}` // no use
  template.class_user_balloon = `${template.class_user_message}` // no use

  template.selector_user_balloon = `${template.selector_chatwidget_transaction} > .${template.class_user_message} > .${template.class_balloon}`
  template.selector_bot_balloon = `${template.selector_chatwidget_transaction} > .${template.class_bot_message} > .${template.class_balloon}`
  template.selector_user_balloon_before = `${template.selector_chatwidget_transaction} > .${template.class_user_message} > .${template.class_balloon}:before`
  template.selector_bot_balloon_before = `${template.selector_chatwidget_transaction} > .${template.class_bot_message} > .${template.class_balloon}:before`

  template.selector_balloon_after = `${template.selector_chatwidget_transaction} .${template.class_balloon}:after`

  template.element_balloon_button = `button`
  template.selector_balloon_button = `.${template.class_balloon} ${template.element_balloon_button}`
  template.selector_listtype_message_button = `.${template.class_balloon} .question-block ${template.element_balloon_button}`

  //do_scroll
  template.selector_dom_bot_img = `.${template.class_bot_message} > .bot-img`;
  template.selector_dom_chatwidget_balloon = `.${template.class_bot_message} > .${template.class_balloon}`;
  //var $dom_bot_img = $(template.selector_chatwidget_body).find(".chat-img.pull-left:last");
  //var $dom_comment = $(template.selector_chatwidget_body).find(".from-them:last");



  template.selector_button_inside_balloon = `.${template.class_bot_message} ${template.class_balloon} button`

  template.selector_time_disp = `${template.selector_chatwidget_transaction} > [class$="-block"] > .time-disp`


  template.body_id = 'bs-chatbot-base'
  template.body = `
    <div id="${template.body_id}">

      <div id="bs-scroll-area">

      </div>
      <span id="bs-mark-of-scroll-area-end" name="mark-of-scroll-area-end" style="font-size:0; line-height:0">&nbsp;</span>
      <div id="bs-go-lastline">
        <a id="bs-go-last" href="#bs-mark-of-scroll-area-end">▼</a>
      </div>

    <!-- End of #bs-chatbot-base -->
    </div>
    `;

  // widget footer definition

  template.send_msg_button_id = 'bs-user-input-button'
  template.send_msg_text_id = 'bs-user-input-area'

  template.footer_id = 'bs-chatbot-console'
  template.footer = `
    <div id="bs-chatbot-console">
      <input id="${template.send_msg_text_id}" type="text" name="user-input-data" maxlength="256" placeholder="入力欄">
      <input id="${template.send_msg_button_id}" type="button" value="送信" onclick="">
      <!-- End of #bs-chatbot-console-->
    </div>
    `;

  return template;
}

export function generate_tag(ca, va, opt, id) {
  let conf = define_conf({});

  var ret = "";
  switch (ca){
    case "d": // date
      ret = '<div class="start-block"><p>' + va + '</p></div>';
      break;
    case "t": // time
      ret = '<div class="time_disp">' + va + '</div>';
      break;
    case "i": // icon image
      var bot_icon_img = conf.chatwidget_bot_icon_url;
      // if (conf.isDebug === true) { console.debug(bot_icon_img) }
      ret = '<span class="chat-img pull-left"><img src="' + bot_icon_img + '" alt="Bot" class="img-circle"></span>';
      break;
    case "crs": // carousel
      ret = ['<div id="carousel-area' + id + '" class="carousel slide" data-interval="false">',
            '  <!-- indicator  -->',
            '  <ol class="carousel-indicators">',
            opt,
            '  </ol>',
            '  <!-- images -->',
            '  <div class="carousel-inner">',
            va,
            '  </div>',
            '  <!-- slider button : left/right -->',
            '  <a class="left carousel-control" href="#carousel-area' + id + '" data-slide="prev">',
            '    <span class="glyphicon glyphicon-chevron-left" aria-hidden="true"></span>',
            '  </a>',
            '  <a class="right carousel-control" href="#carousel-area' + id + '" data-slide="next">',
            '    <span class="glyphicon glyphicon-chevron-right" aria-hidden="true"></span>',
            '  </a>',
            '</div>'].join("");
      break;
  }
  return ret;
}

export function add_styles(params_json){
  let stylesheet = params_json.stylesheet;
  let template = params_json.template;

  let conf = define_conf({});

  //benefitter outside style
  jQuery('body').append( jQuery('<link>').attr({
    'rel': 'stylesheet',
    'href': conf.chatwidget_skin_url,
  }))

  jQuery('body').append( jQuery('<link>').attr({
    'rel': 'stylesheet',
    'href': conf.chatwidget_plugin_css_carousel_url,
  }))

/*
  jQuery('body').append( jQuery('<script>').attr({
    'type': 'text/javascript',
    'src': chatwidget_js_url,
  }))
*/

  stylesheet.insertRule(`
    .no-selected-resel {
      opacity: 0.77;
    }
  `, stylesheet.cssRules.length );

  stylesheet.insertRule(`
    p > img {
      max-width: 100%;
    }
  `, stylesheet.cssRules.length );

  stylesheet.insertRule(`
    .${template.class_bot_message} div.carousel-inner {
      /*
        padding: 0 20px 50px 20px;
      */
      padding: 0 20px 0 20px;
    }
  `, stylesheet.cssRules.length );
  
}
