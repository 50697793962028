import * as config_loader from './config_loader.js';
import * as webchat_app from './webchat-client.js';
import * as common_master from './common_master.js';
import * as func from '../js/function.js';

document.addEventListener('DOMContentLoaded', function() {

  /* Definition */
  let configs = config_loader.run();

  let conf  = configs.conf;
  let theme = configs.theme;
  let tmpl  = configs.tmpl;

  let state = {};


  conf.flg_use_theme = (tmpl.theme_name !== 'default') ? true : false;

  conf.flg_window_position_state = false
  conf.flg_load_header = true

  const style = document.createElement("style");
  document.head.appendChild(style);
  const stylesheet = style.sheet;

  state.chatwidget_visibility;
  state.container_position_left;
  state.container_position_top;

  // misumi
  let flg_init_msg_postback_send = false;

  let uid = webchat_app.generate_uuid();

  webchat_app.set_serverside_cookie({config:conf});

  build_chat_widget()

  load_states()
  
  theme.add_styles({config:conf, template:tmpl, stylesheet:stylesheet});

  common_master
    .load_settings({template:tmpl, config:conf, stylesheet:stylesheet})
    .then(rtn => {
      if (conf.isDebug === true) { console.debug('common_master.load_settings', rtn) }

      /*
        let flag_20210105 = false;
        if (flag_20210105 == true) {
          eyecatch_ballon_mockup();
        };
      */

      // if (conf.project === 'benefitter-operator' && conf.environment === 'production'){ }

      load_widget_features()

      onload_show_widget(tmpl, state)

      func.logger_info(conf, 'Benefitter Chat Widget loaded.')
    })

  // * * * * * * * * * * * * * * * * * * * //

  function onload_show_widget(conf, state){

    if (state.chatwidget_visibility === 'visible') {
      show_widget()
      return;
    }
    if (state.chatwidget_visibility === 'hidden') {
      hide_widget()
      return;
    }

    if (conf.default_load_ui === 'widget'){
      show_widget()
      return;
    }
    if (conf.default_load_ui === 'eyecatch-image') {
      hide_widget()
      return;
    }

    show_widget()
    return;
  }


/*
  var message_count = 0;
  const eyecatch_messages = ['こんにちは', 'ありがとう', 'さようなら', 'また会いましょう']

  function eyecatch_ballon_mockup() {
    console.debug("eyecatch_ballon_mockup() start")
    
    stylesheet.insertRule(`
      #chatwidget_eyecatch_balloon {
        width: 200px;
        height: 50px;
        background: #0091EA;
        margin: 20px;
        position: absolute;
        bottom: 70px;
        right: 180px;
        padding: 5px;
      }
    `, stylesheet.cssRules.length );


    // フキダシ 基本
    stylesheet.insertRule(`
      #chatwidget_eyecatch_balloon {
        border-radius: 12px;
        background: #edf1ee;
      }
    `, stylesheet.cssRules.length );

    // フキダシ しっぽ
    stylesheet.insertRule(`
      #chatwidget_eyecatch_balloon:after {
        content: "";
        position: absolute;
        border: 8px solid transparent;
        border-left: 18px solid #edf1ee;
        transform: rotate(-35deg);
        right: -20px;
      }
    `, stylesheet.cssRules.length );



    stylesheet.insertRule(`
      .eyecatch_animation_fade_in_out {
        animation: fade_in_out 2s ease;
      }
    `, stylesheet.cssRules.length );

    stylesheet.insertRule(`
      .eyecatch_animation_fade_out {
        animation: fade_out 2s ease;
      }
    `, stylesheet.cssRules.length );



    stylesheet.insertRule(`
      .eyecatch_animation_fade_in {
        animation: fade_in 2s ease;
      }
    `, stylesheet.cssRules.length );

    stylesheet.insertRule(`
      .eyecatch_animation_fade_out {
        animation: fade_out 2s ease;
      }
    `, stylesheet.cssRules.length );


    stylesheet.insertRule(`
      @keyframes fade_in {
        0% {
          opacity: 0.1;
        }

        100% {
          opacity: 1.0;
          transform: translateX(-10px);
        }
      }
    `, stylesheet.cssRules.length );

    stylesheet.insertRule(`
      @keyframes fade_out {
        0% {
          opacity: 1.0;
        }

        100% {
          opacity: 0.1;
          transform: translateX(10px);
        }
      }
    `, stylesheet.cssRules.length );

    stylesheet.insertRule(`
      @keyframes fade_in_out {
        0%,100% {
          opacity: 0.1;
        }

        50% {
          opacity: 1.0;
          transform: translateX(-10px);
        }
      }
    `, stylesheet.cssRules.length );


    jQuery('body').append(`
      <div id="chatwidget_eyecatch_balloon">
        ${eyecatch_messages[message_count]}
      </div>
    `);

    $('#chatwidget_eyecatch_balloon').addClass('eyecatch_animation_fade_in_out');

  }

  jQuery(document).on('animationend', '#chatwidget_eyecatch_balloon', function(e){

    console.debug('this', $(this))
    console.debug('eyecatch_messages.length', eyecatch_messages.length)
    console.debug('message_count', message_count)

    if ( $(this).hasClass('eyecatch_animation_fade_in') == true ) {
      message_count += 1;
      
      console.debug('message_count + 1 < eyecatch_messages.length', (message_count + 1 < eyecatch_messages.length)?true:false)

      if (message_count + 1 < eyecatch_messages.length) {
        $(this).removeClass('eyecatch_animation_fade_in');
        $(this).css('transform','translateX(-10px)');
        // $(this).css('opacity','1.0');
        $(this).addClass('eyecatch_animation_fade_out');
      }
    }

    if ( $(this).hasClass('eyecatch_animation_fade_out') == true ) {
      $(this).removeClass('eyecatch_animation_fade_out');
      $(this).css('transform','translateX(10px)');
      // $(this).css('opacity','0.1');
      $(this).text(eyecatch_messages[message_count]);
      $(this).addClass('eyecatch_animation_fade_in');
    }


    if ( $(this).hasClass('eyecatch_animation_fade_in_out') == true ) {
      message_count += 1;
      
      console.debug('message_count + 1 < eyecatch_messages.length', (message_count + 1 < eyecatch_messages.length)?true:false)

      if (message_count + 1 < eyecatch_messages.length) {
        $(this).removeClass('eyecatch_animation_fade_in_out');
        $(this).text(eyecatch_messages[message_count]);
        $(this).addClass('eyecatch_animation_fade_in_out');
      }
    }

  })

*/

  /* Action */

  // eyecatch image click action
  jQuery(document).on('click', `#${tmpl.chatwidget_eyecatch_id}`, function(e){
    show_widget()
  })

  // close button click action
  jQuery(document).on('click', `#${tmpl.webchat_close_button_id}`, function(e){
    hide_widget()
  })
  function show_widget(){
    if (conf.isDebug === true) { console.debug('show chatwidget') }
    resize_widget();
    $(`#${tmpl.chatwidget_eyecatch_id}`).hide();
    $(`#${tmpl.container_id}`).show();
    localStorage.setItem('chatwidget_visibility', 'visible')
  }
  function hide_widget(){
    if (conf.isDebug === true) { console.debug('hide chatwidget') }
    $(`#${tmpl.container_id}`).hide();
    $(`#${tmpl.chatwidget_eyecatch_id}`).show();
    localStorage.setItem('chatwidget_visibility', 'hidden')
  }

  // close button hover action
  jQuery(document).on({ 
    'mouseenter': function(){
      if (conf.isDebug === true) { console.debug('hover') }
      $(`#${tmpl.webchat_close_button_id}`).addClass('hover');
    },
    'mouseleave': function(){
      if (conf.isDebug === true) { console.debug('unhover') }
      $(`#${tmpl.webchat_close_button_id}`).removeClass('hover');
    }
  }, `#${tmpl.webchat_close_button_id}`)


  /*
  //webchat button slice test

  jQuery(document).on('click', '#history-disp', function (e){
    //switch history disp
    if (conf.isDebug === true) { console.debug('history-disp clicked'); }
    if (jQuery("#setting-menu > li > a#history-disp").length > 0) {
      if (jQuery("#setting-menu > li > a#history-disp").attr("clicked") == "clicked") {
        jQuery("#setting-menu > li > a#history-disp").removeAttr("clicked");
        jQuery("#setting-menu > li > a#history-disp")[0].innerText = "履歴表示";
      } else {
        jQuery("#setting-menu > li > a#history-disp").attr("clicked", "clicked");
        jQuery("#setting-menu > li > a#history-disp")[0].innerHTML = "履歴表示 &#10004;";
      }
    }
  })
  */

  /* Behavior */

  // 初期ロード時の埋め込み
  function build_chat_widget(){
    
    // append chat widget DOMs

    jQuery('body').append(tmpl.chatwidget_eyecatch)

    jQuery('body').append(tmpl.container)

    if (conf.flg_load_header) {
      jQuery('#'+tmpl.container_id).append(tmpl.header)
    }

    if (conf.flg_window_position_state) {
      state.container_position_left ? jQuery('#'+tmpl.container_id).css({'left': state.container_position_left}) : jQuery('#'+tmpl.container_id).css({'right': '3px'})
      state.container_position_top  ? jQuery('#'+tmpl.container_id).css({'top':  state.container_position_top})  : jQuery('#'+tmpl.container_id).css({'bottom': '3px'})
    }
    else{

      /*
      $(`#${tmpl.container_id}`).position({
        my: "right-20px bottom-20px",
        at: "right bottom",
        of: window,
        collision: "flip",
        within: document,
      });
      */

      stylesheet.insertRule(`
        #${tmpl.container_id} {
          right: 20px;
          bottom: 20px;
          position:absolute;
        }
      `, stylesheet.cssRules.length );
    }

    jQuery(`#${tmpl.header_title_id}`).css('white-space','nowrap')

    jQuery('#'+tmpl.container_id).append(tmpl.body)

    jQuery('#'+tmpl.container_id).append(tmpl.footer)


    if (conf.flg_load_header !== true) {
      jQuery('#'+tmpl.container_id).css({
        'display':'flex',
        'justify-content':'center',
        'align-items':'center',
        'position':'fixed',
      })
    }

  }


  function load_states() {
    state.chatwidget_visibility   = localStorage.getItem('chatwidget_visibility')

    state.container_position_left = localStorage.getItem('container_position_left')

    state.container_position_top  = localStorage.getItem('container_position_top')
  }


  function draw_user_message(msgtext) {
      // get display date
      var date_disp = get_date_time("d");

      // is not display date already?
      if(!($(tmpl.selector_date_disp).length)){
        // is not same date ?
        if (!($(tmpl.selector_date_disp + ' ' + tmpl.selector_date_disp_inner).text() == date_disp)) {
          var date_disp_tags = theme.generate_tag("d", date_disp);
          $(tmpl.selector_chatwidget_transaction).append($(date_disp_tags));
        }
      } else {
        var disp_flag = false;
        $(tmpl.selector_date_disp + ' ' + tmpl.selector_date_disp_inner).each(function(i) {
          if (($(this).text() == date_disp)) {
            disp_flag = true;
          }
        });

        if (disp_flag == false) {
          var date_disp_tags = theme.generate_tag("d", date_disp);
          $(tmpl.selector_chatwidget_transaction).append($(date_disp_tags));
        }
      }

      // get display time
      var time_disp = get_date_time("t");

    if (conf.flg_use_theme !== true) {
      var time_disp_tags = theme.generate_tag("t", time_disp);
      var insert_tags = '<div class="from-me_outer">' + time_disp_tags + '<div class="balloon from-me"><p>' + webchat_app.decorate_text({text:msgtext,template:tmpl}) + '</p></div></div><div class="clear"></div>';
    }
    else {
      var insert_tags = `
        <div class="user-block">
          <div class="time-disp">${time_disp}</div>
          <div class="chatwidget-balloon"><p>${webchat_app.decorate_text({text:msgtext,template:tmpl})}</p></div>
        </div>
        `;
    }
    $(tmpl.selector_chatwidget_transaction).append(insert_tags);
    $(tmpl.selector_chatwidget_body).animate({scrollTop:$(tmpl.selector_chatwidget_body).get(0).scrollHeight});  
  }


  jQuery(function($) {

    $("#"+tmpl.send_msg_button_id).on("click", function(e) {
      // if (conf.isDebug === true) { console.debug(e.target) }
      // if (conf.isDebug === true) { console.debug("#"+tmpl.send_msg_button_id) }
      // if (conf.isDebug === true) { console.debug("#"+tmpl.send_msg_text_id) }

      var msgtext = $("#"+tmpl.send_msg_text_id).val();

      send_message(msgtext);

      // if (conf.isDebug === true) { console.debug(msgtext.length) // todo: max 256? }
      if (msgtext.length > 400) {
        alert('全角200文字以内で入力してください。');
        return false;
      }

      $("#"+tmpl.send_msg_text_id).val('');
    });

    $("#"+tmpl.send_msg_text_id).on("keydown", function(e) {
      if(e.keyCode === 13) {
        $("#"+tmpl.send_msg_button_id).trigger("click");
      }
    });

    $("#"+tmpl.send_msg_text_id).focus();


    // postback button click event
    $(tmpl.selector_chatwidget_body).on("click","button", function() {
      if (conf.isDebug === true) { console.debug('button clicked') }

      // var button_resel = $('#button-resel').data('button-resel-id');
      var button_resel = conf.button_resel;
      if (conf.isDebug === true) { console.debug('button_resel', button_resel) }
    
      // ボタン再選択可の場合
      if (button_resel == true) {

      // ボタン再選択不可
      } else {
        if ($(this).hasClass('clicked')) {
          return false;
        }
      }

      // チャット内modalのopen/close時
      if (/^(#chatModal)/.test($(this).attr('data-target')) || $(this).closest('#chatModal').length > 0 ){
        return;
      }

      $(this).addClass('clicked');
      var that = this;
      $(this).closest(`${tmpl.selector_bot_balloon}`).find('button').each(function() {
        // 一旦付与したクラスを削除
        $(this).removeClass('no-selected-resel');

        if (this != that) {
          // ボタン再選択可の場合
          if (button_resel == true) {
            $(this).addClass('no-selected-resel');
          // ボタン再選択不可
          } else {
            // 選択されたボタン以外を使用不可にする
            $(this).attr('disabled', true);
          }
        }
      });

      var val = $(this).attr('value');
      if (conf.isDebug === true) { console.debug(val) }
      send_postback(val);
    });
  })


  /* * * * * * * * * * * * * * * * */

  function finally_to_run(func_name) {
    if (conf.isDebug === true) { console.debug('finally_to_run, caller function name:', func_name) }

    switch (func_name){
      case 'send_message':{
        if (conf.isDebug === true) { console.debug('clicked the send message button.') }

        // misumi
        if (conf.project === 'misumi-faq' && conf.environment === 'production'){ try{window.sc_f_general_event('chatBot_send_click');}catch(e){} }
        flg_init_msg_postback_send = false

        break;
      }
      case 'send_postback':{
        if (conf.isDebug === true) { console.debug('clicked the menu item button.') }

        // misumi
        if (flg_init_msg_postback_send === true) {
          if (conf.project === 'misumi-faq' && conf.environment === 'production'){ try{window.sc_f_general_event('chatBot_item_click');}catch(e){} }
        }
        else {
          if (conf.isDebug === true) { console.debug('not send.') }
        }
        flg_init_msg_postback_send = false

        break;
      }
      default:{
        break;
      }
    }
  }

  /*
  jQuery(document).on('click',`${tmpl.send_msg_button_id}`,function (e) {
    console.log('')
  })

  jQuery(document).on('click',`${tmpl.selector_button_inside_balloon}`,function (e) {
    console.log('')
  })
  */



  function send_message(msgtext) {
    var send_message_enabled = true;

    if ($(`#${tmpl.send_msg_button_id}`).attr('disabled') == 'disabled' 
    ||  $(`#${tmpl.send_msg_text_id}`).attr('disabled') == 'disabled') {
      send_message_enabled = false;
    }

    if (!msgtext) {
      if (conf.isDebug === true) { console.debug('message text empty.') }
      return;
    }

    if (msgtext.length > 400) {
      if (conf.isDebug === true) { console.debug('message text over max limit.') }
      return;
    }


    if (send_message_enabled === false) {
      if (conf.isDebug === true) { console.debug('send_message is disabled now.') }
      return
    }
    else
    {
      if (conf.isDebug === true) { console.debug('send_message is enabled.') }

      // ボタンを無効化し、二重送信を防止
      $(`#${tmpl.send_msg_button_id}`).prop('disabled', true);
      $(`#${tmpl.send_msg_text_id}`).prop('disabled', true);
    }

    if (conf.isDebug === true) { console.debug('tmpl') }
    if (conf.isDebug === true) { console.debug(tmpl) }

    draw_user_message(msgtext);

    var url = `${conf.benefitter_origin}/bot`

    var requestData = JSON.stringify ({
      "provider":"webchat",
      "type":"message",
      "source": {
        "type":"user",
        "uid": uid,
      },
      "message":msgtext,
    })

    fetch(url, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: {
        'Authorization': 'Token ' + conf.benefitter_app_token,
        'X-Benefitter-App-Id': conf.benefitter_app_id,
        'Content-Type': 'application/json',
        'X-Bro-Signature': 'true',
      },
      body: requestData

    })
    .then(response => response.json())
    .then(jsonBody => {
      if (conf.isDebug === true) { console.debug('send_message') }
      recieve_message(jsonBody)
      
      $(`#${tmpl.send_msg_button_id}`).prop('disabled',false);
      $(`#${tmpl.send_msg_text_id}`).prop('disabled',false);
    })
    .finally(function () {
      finally_to_run('send_message')
    })
  }


  /*
  function send_message(msgtext) {
    if (!msgtext) {
      return;
    }

    if (msgtext.length > 400) {
      return;
    }

    // get display date
    var date_disp = get_date_time("d");

    // is not display date already?
    if(!($(`${tmpl.selector_date_disp}`).length)){
      // is not same date ?
      if (!($(`${tmpl.selector_date_disp} ${tmpl.selector_date_disp_inner}`).text() == date_disp)) {
        var date_disp_tags = theme.generate_tag("d", date_disp);
        $(`${tmpl.selector_chatwidget_transaction}`).append($(date_disp_tags));
      }
    } else {
      var disp_flag = false;
      $(`${tmpl.selector_date_disp} ${tmpl.selector_date_disp_inner}`).each(function(i) {
        if (($(this).text() == date_disp)) {
          disp_flag = true;
        }
      });

      if (disp_flag == false) {
        var date_disp_tags = theme.generate_tag("d", date_disp);
        $(`${tmpl.selector_chatwidget_transaction}`).append($(date_disp_tags));
      }
    }

    // get display time
    var time_disp = get_date_time("t");
    var time_disp_tags = theme.generate_tag("t", time_disp);
    $(`${tmpl.selector_chatwidget_transaction}`).append($('<div class="from-me_outer">' + time_disp_tags + '<div class="balloon from-me"><p>' + webchat_app.decorate_text({text:msgtext,template:tmpl}) + '</p></div></div><div class="clear"></div>'));
    $("`${tmpl.selector_chatwidget_body}`").animate({scrollTop:$('`${tmpl.selector_chatwidget_body}`').get(0).scrollHeight});

    var reqmsg = {
      type: 'message',
      message: msgtext
    }

    $.ajax({
      headers: {
        'X-Bro-Signature': 'true'
      },
      dataType: 'json',
      data: reqmsg,
      xhrFields: {
        withCredentials: true
      },
      url: '<%= bot_path %>',
      success: function(data) {
        recieve_message(data);
      },
      error: function(jqXHR, textStatus, errorThrown) {
        if (conf.isDebug === true) { console.debug("error:" + textStatus);; }
        if (conf.isDebug === true) { console.debug("detail:" + jqXHR.responseText);; }
      },
      complete: function() {
      }
    });
  }
  */

  function send_postback(data) {

    var url = `${conf.benefitter_origin}/bot`

    var requestData = JSON.stringify({
      "provider":"webchat",
      "type":"postback",
      "source": {
        "type":"user",
        "uid": uid,
      },
      "postback": { data: data }
    })

    fetch(url, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: {
        'Authorization': 'Token ' + conf.benefitter_app_token,
        'X-Benefitter-App-Id': conf.benefitter_app_id,
        'Content-Type': 'application/json',
        'X-Bro-Signature': 'true',
      },
      body: requestData
    })
    .then(response => response.json())
    .then(jsonBody => {
      if (conf.isDebug === true) { console.debug('send_postback') }
      recieve_message(jsonBody)
    })
    .finally(finally_to_run('send_postback'))
  }

  /*
  const benefitter_bot_url  = conf.benefitter_origin + '/bot/'

  function send_postback(data) {
    if (conf.isDebug === true) { console.debug('send_postback') }
    if (conf.isDebug === true) { console.debug(data) }
    var reqmsg = {
      type: 'postback',
      postback: { data: data }
    }

    $.ajax({
      headers: {
        'X-Bro-Signature': 'true'
      },
      dataType: 'json',
      data: reqmsg,
      xhrFields: {
        withCredentials: true
      },
      url: benefitter_bot_url, //'<%= bot_path %>',
      success: function(data) {
        recieve_message(data);
      },
      error: function(jqXHR, textStatus, errorThrown) {
        if (conf.isDebug === true) { console.debug("error:" + textStatus);; }
        if (conf.isDebug === true) { console.debug("detail:" + jqXHR.responseText);; }
      },
      complete: function() {
      }
    });
  }
  */




  function getImageModal(image_url, disp_text, i) {
    // 使用済みのmodalのid"#chatModal.."を取得し、被らないidを設定する
    for (var j = 0; $("[id^=chatModal]").length; j++) {
      if ($("#chatModal" + i).length > 0 || current_modal_index_array.indexOf(i) != -1) {
        i++;
        continue;
      }else{
        current_modal_index_array.push(i);
        break;
      }
    }

    var html_modal = '<a data-toggle="modal" data-target="#chatModal' + i + '">'
                    + disp_text
                    + ' <span class="glyphicon glyphicon-new-window"></span>'
                  + '</a>'
                  + '<div class="modal fade" id="chatModal' + i + '" tabindex="-1" role="dialog" aria-labelledby="chatModalLabel">'
                    + '<div class="modal-dialog modal-lg modal-dialog-center">'
                      + '<div class="modal-content" id="chatModal">'
                        + '<div class="modal-header">'
                          + '<button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>'
                        + '</div>'
                        + '<div class="modal-body">'
                          + '<img border="0" src="' + image_url + '" class="img-responsive" alt="' + image_url + '">'
                        + '</div>'
                      + '</div>'
                    + '</div>'
                  + '</div>'

    return html_modal;  
  }

  // リンクを新規windowで開く
  function openWindow() {
    if ($(this).data('link')) {
      var link_target = $(this).data('link')
    } else {
      var link_target = $(this)[0].text
    }

    var width = $('#'+tmpl.send_msg_text_id).data('link-new-window-width');
    var height = $('#'+tmpl.send_msg_text_id).data('link-new-window-height');

    var img_obj = $('#' + $(this).data('img-id'))[0]
    if (width){
      // 汎用マスタの設定値を適用
    }else if(img_obj) {
      // 表示する画像のサイズを適用
      var width = img_obj.naturalWidth;
    }else{
      var width = $(window).width();
    }
    if (height){
      // 汎用マスタの設定値を適用
    }else if(img_obj){
      // 表示する画像のサイズを適用
      var height = img_obj.naturalHeight;
    }else{
      var height = $(window).height();
    }

    var option = 'width=' + width + ',height=' + height + ', scrollbars=yes, resizable=yes';
    window.open(link_target, '', option);
  }


  function recieve_message(data) {
    if (conf.isDebug === true) { console.debug('recieve_message') }
    if (conf.isDebug === true) { console.debug(data) }
    if (!data) {
      return;
    }

    //if (conf.isDebug === true) { console.debug(JSON.stringify(data));; }
    var messages = [];
    if (Array.isArray(data)) {
      messages = data;
    } else {
      messages.push(data);
    }

    var icon_tags = theme.generate_tag("i", conf.chatwidget_bot_icon_url);

    // get display date
    var date_disp = get_date_time("d");

    // is not display date already?
    if(!($(tmpl.selector_date_disp).length)){
      // is not same date ?
      if (!($(tmpl.selector_date_disp + ' ' + tmpl.selector_date_disp_inner).text() == date_disp)) {
        var date_disp_tags = theme.generate_tag("d", date_disp);
        $(tmpl.selector_chatwidget_transaction).append($(date_disp_tags));
      }
    } else {
      var disp_flag = false;
      $(tmpl.selector_date_disp + ' ' + tmpl.selector_date_disp_inner).each(function(i) {
        if (($(this).text() == date_disp)) {
          disp_flag = true;
        }
      });

      if (disp_flag == false) {
        var date_disp_tags = theme.generate_tag("d", date_disp);
        $(tmpl.selector_chatwidget_transaction).append($(date_disp_tags));
      }
    }

  //  var button_align = $('#button-align').data('button-align-id').toLowerCase();
    var button_align = '';
    if (button_align == "") {
      button_align = "center";
    }

    for (var i = 0; i < messages.length; i++) {
      var response = messages[i];

      // get display time
      var time_disp = get_date_time("t");
      var time_disp_tags = theme.generate_tag("t", time_disp);
      
      switch (response.message.type) {
        case "text":
          if (conf.flg_use_theme !== true) {
            var contents =  $('<div class="from-them_outer">' + icon_tags + '<div class="balloon from-them"><p>' + webchat_app.decorate_text({text:response.message.text,template:tmpl}) + '</p></div>' + time_disp_tags + '</div><div class="clear"></div>');
            contents.find('.openWindowButton').click(openWindow);
          }
          else {
            var contents =  `
              <div class="bot-block">
                <div class="bot-img"></div>
                <div class="chatwidget-balloon"><p>${webchat_app.decorate_text({text:response.message.text,template:tmpl}) }</p></div>
                <div class="time-disp">${time_disp}</div>
              </div>
            `
          }

          $(tmpl.selector_chatwidget_transaction).append(contents);
          do_scroll();
          break;

        case "attachment":
          var attachments = response.message.attachments;
          for (var j = 0; j < attachments.length; j++) {
            var att_response = attachments[j];
            if (att_response.type == "image") {
              var img_url = att_response.url;
              var img = new Image()
              img.addEventListener('load', function(event) {
                do_scroll();
              });
              img.src = img_url

              if (conf.flg_use_theme !== true) {
                var contents = $('<div class="from-them_outer">' + icon_tags + '<div class="balloon from-them"><p><img src="' + img_url + '" alt="image"></p></div>' + time_disp_tags + '</div><div class="clear"></div>');
              }
              else {
                var contents =  `
                  <div class="bot-block">
                    <div class="bot-img"></div>
                    <div class="chatwidget-balloon"><p><img src="${img_url}" alt="image"></p></div>
                    <div class="time-disp">${time_disp}</div>
                  </div>
                `
              }
              $(tmpl.selector_chatwidget_transaction).append(contents);
            }
          }
          break;

        case "confirm":
          var columns = response.message.columns;
          for (var j = 0; j < columns.length; j++) {
            var clm_response = columns[j];
            var actions = clm_response.actions;
            if (conf.isDebug === true) { console.debug(actions) }
            var button_list = make_buttons(actions);

            if (conf.flg_use_theme !== true) {
              var contents = $('<div class="from-them_outer">' + icon_tags + '<div id="from-them" class="balloon from-them"><p>' + webchat_app.decorate_text({text:clm_response.text,template:tmpl}) + '</p><div class="text-center">' + button_list + '</div></div>' + time_disp_tags + '</div><div class="clear"></div>');
              contents.find('.openWindowButton').click(openWindow);
            }
            else{
              var contents = `
                  <div class="bot-block">
                  <div class="bot-img"></div>
                  <div class="chatwidget-balloon"><p>${webchat_app.decorate_text({text:clm_response.text,template:tmpl})}</p><div class="yesno-block">${button_list}</div></div>
                  <div class="time-disp">${time_disp}</div>
                </div>
              `
            }
            
            $(tmpl.selector_chatwidget_transaction).append(contents);
            do_scroll();
          }
          break;

        case "list":
          var columns = response.message.columns;
          for (var j = 0; j < columns.length; j++) {
            var clm_response = columns[j];
            var actions = clm_response.actions;
            var button_list = make_buttons(actions, 1);

            if (conf.flg_use_theme !== true) {
              var contents = $('<div class="from-them_outer">' + icon_tags + '<div class="balloon from-them"><p>' + webchat_app.decorate_text({text:clm_response.text,template:tmpl}) + '</p><div class="text-' + button_align + ' list-button">' + button_list + '</div></div>' + time_disp_tags + '</div><div class="clear"></div>');
              contents.find('.openWindowButton').click(openWindow);
            }
            else {
              var contents = `
              <div class="bot-block">
                <div class="bot-img"></div>
                <div class="chatwidget-balloon"><p>${webchat_app.decorate_text({text:clm_response.text,template:tmpl})}</p><div class="question-block">${button_list}</div></div>
                <div class="time-disp">${time_disp}</div>
              </div>
              `
            }

            $(tmpl.selector_chatwidget_transaction).append(contents);
            do_scroll();
          }
          break;

        case "carousel":
          var columns = response.message.columns;
          var crs_images = "";
          var clm_response_title = "";
          var crs_inds = "";
          var crs_active_class = "";
          var rdm = Math.floor( Math.random() * 1000 ); // random number up to 999
          for (var j = 0; j < columns.length; j++) {
            var clm_response = columns[j];
            if (j == 0) {
              crs_active_class = " active";
            } else {
              crs_active_class = "";
            }

            if (!clm_response.title) {
              clm_response_title = clm_response.text;
            } else {
              clm_response_title = clm_response.title;
            }

            // Initialize
            var button_list = "";
            if (clm_response.actions) {
              var actions = clm_response.actions;
              button_list = make_buttons(actions, 1);
            }

            if (!clm_response.url) {
              crs_images += '<div class="item ' + crs_active_class + '"><div class="carousel-caption-alt"><p class="crs-caption-text">' + webchat_app.decorate_text({text:clm_response.text,template:tmpl}) + '<div class="text-' + button_align + ' carousel-button">' + button_list + '</div></p></div></div>';
            } else {
              crs_images += '<div class="item ' + crs_active_class + '"><img src="' + clm_response.url + '" alt="' + clm_response_title + '"><div class="carousel-caption-alt"><p class="crs-caption-text">' + webchat_app.decorate_text({text:clm_response.text,template:tmpl}) + '<div class="text-' + button_align + ' carousel-button">' + button_list + '</div></p></div></div>';
            }
            crs_inds += '<li data-target="#carousel-area' + rdm + '" data-slide-to="' + j + '" class="' + crs_active_class + '"></li>';
          }
          var crs_tag = theme.generate_tag("crs", crs_images, crs_inds, rdm);

          if (conf.flg_use_theme !== true) {
            var contents = $('<div class="from-them_outer">' + icon_tags + '<div class="balloon from-them"><p>' + crs_tag + '</p></div>' + time_disp_tags + '</div><div class="clear"></div>');
            contents.find('.openWindowButton').click(openWindow);
          }
          else {
            var contents = `
                <div class="bot-block">
                <div class="bot-img"></div>
                <div class="chatwidget-balloon"><p>${crs_tag}</p></div>
                <div class="time-disp">${time_disp}</div>
              </div>
            `
          }

          //$("`${tmpl.selector_chatwidget_body}` section.sec-current").append(contents);
          $(tmpl.selector_chatwidget_transaction).append(contents);

          //子要素の縦横サイズの最大値を求める
          var carousel_inner = $('#carousel-area' + rdm).find(".carousel-inner");
          var items = carousel_inner.children();
          var o_height, max_height;
          var o_width, max_width;

          for (var k = 0; k < items.length; k++) {
            var item = items.eq(k);

            if (o_height == null) {
              o_height = 0;
            }
            if (o_width == null) {
              o_width = 0;
            }

            item.find("img").bind("load",function(){
              //画像の読み込み
            });

            if (o_height < item.height()) {
              max_height = item.height();
            }
            o_height = item.height();

            if (o_width < item.width()) {
              max_width = item.width();
            }
            o_width = item.width();
          }
          //最大サイズで固定表示
          //$('#carousel-area' + rdm).css({'height':max_height,'width':max_width});

          do_scroll();
          break;

        case "imagemap":
          var contents =  $('<div class="from-them_outer">' + icon_tags + '<div class="balloon from-them"><p>[imagemap]<br>' + response.message.alt_text + '</p></div>' + time_disp_tags + '</div><div class="clear"></div>');
          $(tmpl.selector_chatwidget_transaction).append(contents);
          do_scroll();
          break;

        case "card":
          var card_contents = '';
          var attachments = response.message.attachments;
          for (var j = 0; j < attachments.length; j++) {
            var att_response = attachments[j];
            var actions = att_response.card_actions;
            var button_list = make_buttons(actions, 1);
            var card_elements = ''
            card_elements += '<blockquote class="blockquote card-fields">';
            card_elements += '<p style="font-size: 1.25rem;">' + att_response.fields_title + '</p>';
            card_elements += '<p style="font-size: 1.25rem;">' + att_response.card_fields[0].title + '</p>';
            card_elements += '<p style="font-size: 1.1rem;">' + att_response.card_fields[0].text + '</p>';
            card_elements += '</blockquote>';
            card_elements += '<blockquote class="blockquote card-actions">';
            if (att_response.actions_title != null) {
              card_elements += '<p style="font-size: 1.1rem;">' + att_response.actions_title + '</p>';
            }
            card_elements += '<div class="text-center">' + button_list + '</div>';
            card_elements += '</blockquote>';
            card_contents += card_elements;
          }

          if (conf.flg_use_theme !== true) {
            var contents = '<div class="from-them_outer">' + icon_tags + '<div class="balloon from-them"><p>' + webchat_app.decorate_text({text:response.message.text,template:tmpl}) + '</p>' + card_contents + '</div>' + time_disp_tags + '</div><div class="clear"></div>';
          }
          else {
            var contents = `
                <div class="bot-block">
                <div class="bot-img"></div>
                <div class="chatwidget-balloon"><p>${webchat_app.decorate_text({text:response.message.text,template:tmpl})}</p>${card_contents}</div>
                <div class="time-disp">${time_disp}</div>
              </div>
            `
          }

          $(tmpl.selector_chatwidget_transaction).append(contents);
          do_scroll();
          break;

        default :
          break;
      }

      // if (conf.isDebug === true) { console.debug('next_message:' ,messages[i+1], func.isPresent(messages[i+1])); }
      // if (func.isPresent(messages[i+1]) === true) {
      //   var startMsec = new Date();
      //   while (new Date() - startMsec < 800);
      // }

    }
  }

  function get_date_time(dort, datt) {
    // *** format time & date
    if (typeof datt != "undefined") { // is date data not empty?
      var dt = new Date(Date.parse(datt));
    } else {
      var dt = new Date(); // today's date
    }

    // *** format time & date
    var dtYear = dt.getFullYear();
    var dtMonth = dt.getMonth()+1;
    var dtDate = dt.getDate();
    var dtDateT = ["日","月","火","水","木","金","土"];
    var dtDay = dtDateT[dt.getDay()];
    var dtHours = dt.getHours();
    var dtMinutes = dt.getMinutes();
    dtMinutes = ( '00'  + dtMinutes ).slice( -2 ); // zero padding
    var dtSeconds = dt.getSeconds();
    //var date_disp = dtMonth + "/" + dtDate;
    var date_disp = dtYear + "/" + dtMonth + "/" + dtDate;
    var time_disp = dtHours + ":" + dtMinutes;
    var ret;

    switch (dort){
      case "d":
        ret = date_disp;
        break;
      case "t":
        ret = time_disp;
        break;
    }
    return ret;
  }


  function getFileName(file_path) {  
    file_name = file_path.substring(file_path.lastIndexOf('/')+1, file_path.length);  
    return file_name;  
  }

  function make_buttons(mb_actions, isList, next_data) {
    var button_list = "";
    for (var k = 0; k < mb_actions.length; k++) {
      var act = mb_actions[k];
      if (isList) {
        if (act.data) {
          if (next_data == null) {
            button_list += '<p><button class="btn btn-sub btn-sm btn-chat" value=\'' + act.data + '\'>' + act.label + '</button></p>';
          }else {
            var data = $.parseJSON(act.data);
            var data_keys = Object.keys(data);
            var clicked = false;
            for (var i = 0; i < data_keys.length; i++) {
              if (next_data[data_keys[i]] != "undefined") {
                if (next_data[data_keys[i]] == data[data_keys[i]]) {
                  clicked = true;
                }
              }
            }

            if (clicked) {
              button_list += '<p><button class="btn btn-sub btn-sm btn-chat clicked" value=\'' + act.data + '\'>' + act.label + '</button></p>';
            }
            else {
              button_list += '<p><button disabled="disabled" class="btn btn-sub btn-sm btn-chat" value=\'' + act.data + '\'>' + act.label + '</button></p>';
            }
          }
        }
        if (act.uri) {
          button_list += '<p><a href="' + act.uri + '" target="_blank">' + act.label + '</a></p>';
        }
      } else {
        if (act.data) {
          if (next_data == null) {
            button_list += '<button class="btn btn-sub btn-sm btn-chat" value=\'' + act.data + '\'>' + act.label + '</button>';
          }else {
            var data = $.parseJSON(act.data);
            var data_keys = Object.keys(data);
            var clicked = false;
            for (var i = 0; i < data_keys.length; i++) {
              if (next_data[data_keys[i]] != "undefined") {
                if (next_data[data_keys[i]] == data[data_keys[i]]) {
                  clicked = true;
                }
              }
            }

            if (clicked) {
              button_list += '<button class="btn btn-sub btn-sm btn-chat clicked" value=\'' + act.data + '\'>' + act.label + '</button>';
            }
            else {
              button_list += '<button disabled="disabled" class="btn btn-sub btn-sm btn-chat" value=\'' + act.data + '\'>' + act.label + '</button>';
            }
          }
        }
        if (act.uri) {
          button_list += '<p><a href="' + act.uri + '" target="_blank">' + act.label + '</a></p>';
        }
      }
    }
    return button_list;
  }

  function do_scroll() {
    var $dom_bot_img = $(tmpl.selector_chatwidget_body).find(tmpl.selector_dom_bot_img).last();
    var $dom_comment = $(tmpl.selector_chatwidget_body).find(tmpl.selector_dom_chatwidget_balloon).last();

    // // scroll height - object height - margin( 35px )
    // var targetY = $(tmpl.selector_chatwidget_body).get(0).scrollHeight - $dom_bot_img.height() - $dom_comment.height() - 35;

    var targetY = $(tmpl.selector_chatwidget_body).get(0).scrollHeight - $dom_comment.height() - 90;

    // // scroll end
    // var chatwidget_body = $(tmpl.selector_chatwidget_body).get(0);
    // var targetY = chatwidget_body.scrollHeight - chatwidget_body.clientHeight;

    $(tmpl.selector_chatwidget_body).animate({scrollTop:targetY});
  }

  // add load event
  if (window.location.pathname == '/webchat') {
    window.addEventListener("load", postSize, false);
  }

  //---------------
  //   init message
  //---------------

  request_init_message()

  //---------------
  //   history 
  //---------------

  // get history data
  var json_history_data;
  $.ajax({
    type: 'GET',
    dataType: 'json',
    xhrFields: {
      withCredentials: true
    },
    url: conf.benefitter_origin+'/webchat/get_history.json',
    success: function(history) {
      // set user history disp
      if (history.user_history_disp == "true")
      {
        if ($("#setting-menu > li > a#history-disp").length > 0) {
          $("#setting-menu > li > a#history-disp").attr("clicked", "clicked");
          $("section.sec-history").css("display", "block");
        }
      }
      // set history webchat panel
      if (history.data != null) {
        json_history_data = history;
        set_history();
      }
    },
    error: function(jqXHR, textStatus, errorThrown) {
      if (conf.isDebug === true) { console.debug("error:" + textStatus);; }
      if (conf.isDebug === true) { console.debug("detail:" + jqXHR.responseText);; }
    },
    complete: function() {
    }
  });

  if ($('#setting-menu li a').length == 0) {
    $("div.header-setting > div").addClass("setting-menu-no-disp");
  }

  // setting menu click event
  $('#setting-menu li a').click(function () {
    // history-disp
    if ($(this)[0].id == "history-disp") {
      if ($("#setting-menu > li > a#history-disp").attr("clicked") == "clicked"){
        $("#setting-menu > li > a#history-disp").removeAttr("clicked");
          $("section.sec-history").css("display", "none");
      } else {
        $("#setting-menu > li > a#history-disp").attr("clicked", "clicked");
          $("section.sec-history").css("display", "block");
      }
      change_history_disp();
    }
  });

  // scroll top more disp history
  $(tmpl.selector_chatwidget_body).scroll(function() {
    if ($(this).scrollTop() == 0) {
      scroll_history_disp();
    }
  });


  $(`#${tmpl.webchat_home_button_id}`).on('click',function(){
    request_init_message(); // benefitter.ai
  })


function read_flow_code(){
  let flow_code;
  if (conf.flow_code_use_js_mapping === true) {
    let key = $('input#flow_code').val();
    if (func.isPresent(conf.flow_code_mapping) === true){
      flow_code = conf.flow_code_mapping[key]
    }
  }
  else if (conf.flow_code_use_js_mapping === false) {
    flow_code = $('input#flow_code').val();
  }

  if (func.isPresent(flow_code) === true) {
    return flow_code;
  }
  else {
    return null;
  }
}

/* */
function request_init_message(){
  let flow_code = read_flow_code();
  console.debug('flow_code', flow_code)

  $.ajax({
    headers: {
      'X-Bro-Signature': 'true'
    },
    data: {
      'ic': flow_code,
      'source': {'uid': uid},
    },
    type:'GET',
    dataType: 'json',
    cache:false,
    xhrFields: {
      withCredentials: true
    },
    url: conf.benefitter_origin+'/webchat/init_messages', //'<%= init_messages_path %>',
    success: function(data) {
      recieve_message(data);
    },
    error: function(jqXHR, textStatus, errorThrown) {
      if (conf.isDebug === true) { console.debug("error:" + textStatus);; }
      if (conf.isDebug === true) { console.debug("detail:" + jqXHR.responseText);; }
    },
    complete: function() {
    }
  });

}

  function set_history() {
    var tran = json_history_data.data;

    for (var i = 0; i < tran.length; i++) {
      var transaction = tran[i];
      var next_request = null;

      if (tran.length-1 > i) {
        next_request = tran[i+1].request;
      }

      // get display date
      var date_disp = _format(transaction.created_at, '%Y/%m/%d'); //date.formats.default

      // is not display date already?
      if (!($('section.sec-history div.date_disp').length)){
        // is not same date ?
        if (!($("section.sec-history div.date_disp div.date_disp_inner").text() == date_disp)) {
          var date_disp_tags = theme.generate_tag("d", date_disp);
          atr_class = 'class=';

          if (date_disp_tags.indexOf(atr_class) > -1) {
            var date_disp_tags = date_disp_tags.slice(0, date_disp_tags.indexOf(atr_class)+atr_class.length) + '"history ' + date_disp_tags.slice(date_disp_tags.indexOf(atr_class)+atr_class.length+1, date_disp_tags.length - date_disp_tags.indexOf(atr_class)+atr_class.length);
          }

          $(tmpl.selector_chatwidget_body + ' ' + tmpl.selector_chatwidget_history).append(date_disp_tags);
        }
      }
      else {
        if (!($('section.sec-history div.date_disp')[$('section.sec-history div.date_disp').length - 1].innerText == date_disp)) {
          var date_disp_tags = theme.generate_tag("d", date_disp);
          atr_class = 'class=';

          if (date_disp_tags.indexOf(atr_class) > -1) {
            var date_disp_tags = date_disp_tags.slice(0, date_disp_tags.indexOf(atr_class)+atr_class.length) + '"history ' + date_disp_tags.slice(date_disp_tags.indexOf(atr_class)+atr_class.length+1, date_disp_tags.length - date_disp_tags.indexOf(atr_class)+atr_class.length);
          }

          $(tmpl.selector_chatwidget_body + ' ' + tmpl.selector_chatwidget_history).append(date_disp_tags);
        }
      }

      // showing request message
      set_request(transaction.request, transaction.created_at);

      // showing response message
      set_response(transaction.response, transaction.created_at, next_request);
    }
    // setup
    change_history_disp();
    scroll_history_disp();
    // scroll_end(); // Change scroll timing: when chat-widget transition to visible. and deleted the function.
  }

  function set_request(data, created_at) {
    var res_data = $.parseJSON(data);
    var messages = [];
    if (Array.isArray(res_data)) {
      messages = res_data;
    } else {
      messages.push(res_data);
    }

    for (var i = 0; i < messages.length; i++) {
      var request = messages[i];

      // get display time
      time_disp = _format(created_at, '%H:%M:%S'); //time.formats.default
      time_disp = get_date_time("t", time_disp);
      time_disp_tags = theme.generate_tag("t", time_disp);

      var contents = "";
      if (request != null) {
        if (request.type != null) {
          switch (request.type) {
            case "message":
              if (request.message.type == "text") {
                contents = '<div class="from-me_outer history history-scroll-hide">' + time_disp_tags + '<div class="balloon from-me"><p>' + webchat_app.decorate_text({text:request.message.text,template:tmpl}) + '</p></div></div><div class="clear"></div>';
              } else if (request.message.type == "attachment") {
                contents = '<div class="from-me_outer history history-scroll-hide">' + time_disp_tags + '<div class="balloon from-me"><p>' + "attachments: " + request.message.attachments + '</p></div></div><div class="clear"></div>';
              } else {
                contents = '<div class="from-me_outer history history-scroll-hide">' + time_disp_tags + '<div class="balloon from-me"><p>' + request.message.attachments + '</p></div></div><div class="clear"></div>';
              }
              $(tmpl.selector_chatwidget_body + ' ' + tmpl.selector_chatwidget_history).append(contents);
              break;

            case "postback":
              // postback request : no display
              //contents = '<div class="from-me_outer history history-scroll-hide">' + time_disp_tags + '<div class="balloon from-me"><p>' + webchat_app.decorate_text({text:request.postback.data,template:tmpl}) + '</p></div></div><div class="clear"></div>';
              //$("`${tmpl.selector_chatwidget_body}` ${tmpl.selector_chatwidget_history}").append(contents);
              break;
          }
        }
      }
    }
  }

  function set_response(data, created_at, next_request) {
    var res_data = $.parseJSON(data);
    var messages = [];
    if (Array.isArray(res_data)) {
      messages = res_data;
    } else {
      messages.push(res_data);
    }

    var icon_tags = theme.generate_tag("i", conf.chatwidget_bot_icon_url);
    for (var i = 0; i < messages.length; i++) {
      var response = messages[i];

      // get display time
      time_disp = _format(created_at, '%H:%M:%S'); //time.formats.default
      time_disp = get_date_time("t", time_disp);
      time_disp_tags = theme.generate_tag("t", time_disp);

      if (response != null) {
        if (response.message != null) {
          var button_align = $('#button-align').data('button-align-id').toLowerCase();
          if (button_align == "") {
            button_align = "center";
          }

          switch (response.message.type) {
            case "text":
              var contents = $('<div class="from-them_outer history history-scroll-hide">' + icon_tags + '<div class="balloon from-them"><p>' + webchat_app.decorate_text({text:response.message.text,template:tmpl}) + '</p></div>' + time_disp_tags + '</div><div class="clear"></div>');
              contents.find('.openWindowButton').click(openWindow);
              $(tmpl.selector_chatwidget_body + ' ' + tmpl.selector_chatwidget_history).append(contents);
              break;

            case "attachment":
              var attachments = response.message.attachments;
              for (var j = 0; j < attachments.length; j++) {
                var att_response = attachments[j];
                if (att_response.type == "image") {
                  var img_url = att_response.url;
                  var contents = $('<div class="from-them_outer history history-scroll-hide">' + icon_tags + '<div class="balloon from-them"><p><img src="' + img_url + '" alt="image"></p></div>' + time_disp_tags + '</div><div class="clear"></div>');
                  $(tmpl.selector_chatwidget_body + ' ' + tmpl.selector_chatwidget_history).append(contents);
                }
              }
              break;

            case "confirm":
              var columns = response.message.columns;
              for (var j = 0; j < columns.length; j++) {
                var clm_response = columns[j];
                var actions = clm_response.actions;
                var next_data = null;
                if (next_request != null) {
                  var json_next_request = $.parseJSON(next_request);
                  if (json_next_request.type == "postback") {
                    next_data = $.parseJSON(json_next_request.postback.data);
                  }
                }
                var button_list = make_buttons(actions, 0, next_data);
                var contents = $('<div class="from-them_outer history history-scroll-hide">' + icon_tags + '<div id="from-them" class="balloon from-them"><p>' + webchat_app.decorate_text({text:clm_response.text,template:tmpl}) + '</p><div class="text-center">' + button_list + '</div></div>' + time_disp_tags + '</div><div class="clear"></div>');
                contents.find('.openWindowButton').click(openWindow);
                $(tmpl.selector_chatwidget_body + ' ' + tmpl.selector_chatwidget_history).append(contents);
              }
              break;

            case "list":
              var columns = response.message.columns;
              for (var j = 0; j < columns.length; j++) {
                var clm_response = columns[j];
                var actions = clm_response.actions;
                var next_data = null;
                if (next_request != null) {
                  var json_next_request = $.parseJSON(next_request);
                  if (json_next_request.type == "postback") {
                    next_data = $.parseJSON(json_next_request.postback.data);
                  }
                }
                var button_list = make_buttons(actions, 1, next_data);
                var contents = $('<div class="from-them_outer history history-scroll-hide">' + icon_tags + '<div class="balloon from-them"><p>' + webchat_app.decorate_text({text:clm_response.text,template:tmpl}) + '</p><div class="text-' + button_align + ' list-button">' + button_list + '</div></div>' + time_disp_tags + '</div><div class="clear"></div>');
                contents.find('.openWindowButton').click(openWindow);
                $(tmpl.selector_chatwidget_body + ' ' + tmpl.selector_chatwidget_history).append(contents);
              }
              break;

            case "carousel":
              var columns = response.message.columns;
              var crs_images = "";
              var clm_response_title = "";
              var crs_inds = "";
              var crs_active_class = "";
              var rdm = Math.floor( Math.random() * 1000 ); // random number up to 999
              var next_data = null;
              var hide_class = "";
              if (next_request != null) {
                var json_next_request = $.parseJSON(next_request);
                if (json_next_request.type == "postback") {
                  next_data = $.parseJSON(json_next_request.postback.data);
                }
              }

              for (var j = 0; j < columns.length; j++) {
                var clm_response = columns[j];
                if (j == 0) {
                  crs_active_class = " active";
                } else {
                  crs_active_class = "";
                }

                if (!clm_response.title) {
                  clm_response_title = clm_response.text;
                } else {
                  clm_response_title = clm_response.title;
                }
            
                // Initialize
                var button_list = "";
                if (clm_response.actions) {
                  var actions = clm_response.actions;
                  button_list = make_buttons(actions, 1, next_data);
                }

                if (!clm_response.url) {
                  crs_images += '<div class="item ' + crs_active_class + '"><div class="carousel-caption-alt"><p class="crs-caption-text">' + webchat_app.decorate_text({text:clm_response.text,template:tmpl}) + '<div class="text-' + button_align + ' carousel-button">' + button_list + '</div></p></div></div>';
                } else {
                  crs_images += '<div class="item ' + crs_active_class + '"><img src="' + clm_response.url + '" alt="' + clm_response_title + '"><div class="carousel-caption-alt"><p class="crs-caption-text">' + webchat_app.decorate_text({text:clm_response.text,template:tmpl}) + '<div class="text-' + button_align + ' carousel-button">' + button_list + '</div></p></div></div>';
                }
                crs_inds += '<li data-target="#carousel-area' + rdm + '" data-slide-to="' + j + '" class="' + crs_active_class + '"></li>';
              }
              var crs_tag = theme.generate_tag("crs", crs_images, crs_inds, rdm);
              var contents = $('<div class="from-them_outer history ' + hide_class + '">' + icon_tags + '<div class="balloon from-them"><p>' + crs_tag + '</p></div>' + time_disp_tags + '</div><div class="clear"></div>');
              contents.find('.openWindowButton').click(openWindow);
              $(tmpl.selector_chatwidget_body + ' ' + tmpl.selector_chatwidget_history).append(contents);

              //子要素の縦横サイズの最大値を求める
              var carousel_inner = $('#carousel-area' + rdm).find(".carousel-inner");
              var items = carousel_inner.children();
              var o_height, max_height;
              var o_width, max_width;

              for (var k = 0; k < items.length; k++) {
                var item = items.eq(k);

                if (o_height == null) {
                  o_height = 0;
                }
                if (o_width == null) {
                  o_width = 0;
                }

                item.find("img").bind("load",function(){
                  //画像の読み込み
                });

                if (o_height < item.height()) {
                  max_height = item.height();
                }
                o_height = item.height();

                if (o_width < item.width()) {
                  max_width = item.width();
                }
                o_width = item.width();
              }
              //最大サイズで固定表示
              //$('#carousel-area' + rdm).css({'height':max_height,'width':max_width});

              break;

            case "imagemap":
              var hide_class = "";
              var contents = $('<div class="from-them_outer history ' + hide_class + '">' + icon_tags + '<div class="balloon from-them"><p>[imagemap]<br>' + response.message.alt_text + '<br>' + response.message.base_url + '</p></div>' + time_disp_tags + '</div><div class="clear"></div>');
              $(tmpl.selector_chatwidget_body + ' ' + tmpl.selector_chatwidget_history).append(contents);
              break;

            default :
              break;
          }
        }
      }
    }
  }

  function change_history_disp() {
    var user_history_disp = false;
    if ($("#setting-menu > li > a#history-disp").length > 0) {
      if ($("#setting-menu > li > a#history-disp").attr("clicked") == "clicked") {
        //$("#setting-menu > li > a#history-disp")[0].innerText = "履歴表示 ?";
        $("#setting-menu > li > a#history-disp")[0].innerHTML = "履歴表示 &#10004;";

        $(".history").removeClass("history-user-hide");

        if ($("section.sec-history > div:not(.history):not(.clear)").length > 0 ) {
          $("section.sec-history > div:not(.history):not(.clear)")[0].childNodes[0].scrollIntoView(true);
        } else {
          // scroll_end(); // Change scroll timing: when chat-widget transition to visible. and deleted the function.
        }
        var user_history_disp = true;
      } else {
        $("#setting-menu > li > a#history-disp")[0].innerText = "履歴表示";
        $(".history").addClass("history-user-hide");

        if ( $("div.date_disp.history").length == $("div.date_disp").length ) {
          $("div.date_disp.history").each(function(i) {
            if (i == ($("div.date_disp.history").length - 1)){
              //$(this).removeClass("history-user-hide");
            }
          });
        }
      }

      // save
      $.ajax({
        type: 'PATCH',
        xhrFields: {
          withCredentials: true
        },
        url: conf.benefitter_origin+'/webchat/save_user_history_disp',
        data: {
          user_history_disp: user_history_disp
        },
        })
        .done(function(data){
        })
        .fail(function(data){
        });
    }
  }

  function scroll_history_disp() {
    if ($(".history-scroll-hide").length > 0) {
      var disp_count = 0;
      // retention before top msg
      if ($(".history:not(.history-scroll-hide):not(.date_disp)").length > 0) {
        var disp_msg_top = $(".history:not(.history-scroll-hide):not(.date_disp)")[0].childNodes[0];
      }

      for (var i = $(".history-scroll-hide").length-1; i >= 0; i--) {
        $($(".history-scroll-hide")[i]).removeClass('history-scroll-hide');
        disp_count = disp_count + 1;
        // disp init history number 
        if (disp_count >= json_history_data.init_history_num) {
          break;
        }
      }

      // scroll before top msg point
      if (typeof(disp_msg_top) != 'undefined') {
        disp_msg_top.scrollIntoView(true);
      }
    }
  }

  //---------------
  //   webchat mode change
  //---------------

  var header_mode_menu_disp, user_attribute_webchat_mode;
  var selected_index = -1;

  // get webchat mode menu (webchat_states) data
  $.ajax({
    type: 'GET',
    dataType: 'json',
    xhrFields: {
      withCredentials: true
    },
    url: conf.benefitter_origin+'/webchat/get_webchat_mode_info.json',
    success: function(dat) {
      // set webchat mode menu disp
      if (!dat.header_mode_menu_disp) {
      } else {
        header_mode_menu_disp = dat.header_mode_menu_disp.toLowerCase();
      }

      if (!dat.user_attribute_webchat_mode) {
      } else {
        user_attribute_webchat_mode = dat.user_attribute_webchat_mode.toLowerCase();
      }

      if (header_mode_menu_disp == "true") {
        // 2階層のbootstrapドロップダウンを生成
        var $modeDiv = $('#header-mode-dropdown');
        var $modeButton = $('<button>').attr({class: "btn btn-default dropdown-toggle pull-left", type: "button", "data-toggle": "dropdown", "aria-expanded": false});
        $modeButton.append($('<span>').attr({id: "header-mode-dropdown-button", class: "pull-left"}).html('共通'));
        $modeButton.append($('<span>').attr("class", "caret pull-right"));
        $modeDiv.append($modeButton);
        var $modeUpperUlist = $('<ul>').attr({id: "header-mode-category", class: "dropdown-menu"});

        var defaultSelectedModeName = ''
        var maxModeNameByteSize = 0;
        var maxByteSizeModeName = '';

        $.each(dat.data, function(index, webchatCategory) {
          if (webchatCategory.name == '') {
            // カテゴリー名が未設定の場合は、子階層を設けず1階層のみ
            var $modeUpperLists = $('<li>').attr({role: "presentation"});

            // ユーザー属性 > webchatState.default_flagがtrue > webchatCategory.sort_numが1 の優先順位で初期選択モードを決定する
            if (user_attribute_webchat_mode) {
              json_data = JSON.parse(webchatCategory.webchat_states[0].data);
              if (json_data.faq_mode == user_attribute_webchat_mode) {
                defaultSelectedModeName = webchatCategory.webchat_states[0].label;
                $modeUpperLists.toggleClass("active");
              }
            } else if (webchatCategory.webchat_states[0].default_flag == true || (defaultSelectedModeName == '' && webchatCategory.sort_num == 1)) {
              defaultSelectedModeName = webchatCategory.webchat_states[0].label;
              $modeUpperLists.toggleClass("active");
            }

            var upperLayerName = webchatCategory.webchat_states[0].label;
            var modeNameByteSize = getByteLength(upperLayerName);
            if (maxModeNameByteSize < modeNameByteSize) {
              maxModeNameByteSize = modeNameByteSize;
              maxByteSizeModeName = upperLayerName;
            }
            var $upperLayerAnchor = $('<a>').attr({class: "dropdown-item", "data-mode-json": webchatCategory.webchat_states[0].data}).html(upperLayerName);
            $modeUpperLists.append($upperLayerAnchor);
          } else {
            // カテゴリー名が設定されている場合は、子階層を設けて2階層とする
            var $modeUpperLists = $('<li>').attr("class", "dropdown-submenu");
            var upperLayerName = webchatCategory.name;
            var $upperLayerAnchor = $('<a>').attr({"data-toggle": "dropdown"}).html(upperLayerName);
            $modeUpperLists.append($upperLayerAnchor);
            var $modeLowerUlist = $('<ul>').attr("class", "dropdown-menu dropdown-menu-right");

            // 子階層のドロップダウン生成
            $.each(webchatCategory.webchat_states, function(i, webchatState) {
              var $modeLowerList = $('<li>').attr("role", "presentation");
              var $lowerLayerAnchor = $('<a>').attr({class: "dropdown-item", "data-mode-json": webchatState.data}).html(webchatState.label);
              var modeNameByteSize = getByteLength(webchatState.label);
              if (maxModeNameByteSize < modeNameByteSize) {
                maxModeNameByteSize = modeNameByteSize;
                maxByteSizeModeName = webchatState.label;
              }
              $modeLowerList.append($lowerLayerAnchor);
              $modeLowerUlist.append($modeLowerList);

              // ユーザー属性のモードデータ > webchatState.default_flagがtrue > webchatCategory.sort_numが1 の優先順位で初期選択モードを決定する
              if (user_attribute_webchat_mode) {
                json_data = JSON.parse(webchatState.data);
                if (json_data.faq_mode == user_attribute_webchat_mode) {
                  defaultSelectedModeName = webchatState.label;
                  $modeLowerList.toggleClass("active");
                }
              } else if (webchatState.default_flag == true || (defaultSelectedModeName == '' && webchatCategory.sort_num == 1 && webchatState.order == 1)) {
                defaultSelectedModeName = webchatState.label;
                $modeLowerList.toggleClass("active");
              }
            });
            $modeUpperLists.append($modeLowerUlist);
          }
          $modeUpperUlist.append($modeUpperLists);
        });
        $modeDiv.append($modeUpperUlist);

        // 非表示要素から表示幅を取得できないので、最大バイト数のモード名をいったんボタンに適用して取得した幅に合わせる
        $("#header-mode-dropdown-button").text(maxByteSizeModeName);
        var maxOuterWidth = 0;

        $('#header-mode-dropdown').ready(function() {
          // outerWidth()で取得できる幅は小数点以下が切り捨てられるため、1px余分に確保しておく
          maxOuterWidth = $('#header-mode-dropdown').find('button.dropdown-toggle').outerWidth() + 1;
        });
        // 親階層の表示幅もボタンに合わせる
        $('#header-mode-category').width(maxOuterWidth);

        // ボタン名は本来初期表示されるべきモード文言に戻すが、表示幅は最大バイト数のモード名分確保する
        $("#header-mode-dropdown-button").text(defaultSelectedModeName);
        $('#header-mode-dropdown').find('button.dropdown-toggle').outerWidth(maxOuterWidth);
      }
    },
    error: function(jqXHR, textStatus, errorThrown) {
      if (conf.isDebug === true) { console.debug("error:" + textStatus);; }
      if (conf.isDebug === true) { console.debug("detail:" + jqXHR.responseText);; }
    },
    complete: function() {
    }
  });

  // mode change event
  $('#header-mode-dropdown').on('click', ".dropdown-item", function() {
    var beforeSelected = $("#header-mode-dropdown li.active").find('.dropdown-item')
    var nowSelected = $(this)
    // JSON値に差分がある場合のみポストバックする
    if (!nowSelected.data("mode-json") || !beforeSelected.data("mode-json") || JSON.stringify(nowSelected.data("mode-json")) == JSON.stringify(beforeSelected.data("mode-json"))) {
    } else {
      $("#header-mode-dropdown-button").text(nowSelected.text());
      // 選択状態のモードを表すclass属性の付け替え
      beforeSelected.parent('li').toggleClass("active");
      nowSelected.parent('li').toggleClass("active");
      send_postback(JSON.stringify(nowSelected.data("mode-json")));
    }
  });

  //---------------
  //   action menu button
  //---------------

  // action menu button click event
  $(".header-action").on("click","button", function() {
    var val = $(this).attr('value');
    if (!val) {
    } else {
      send_postback(val);
    }
  });

  function _format(date, format) {
    if (!date || !format) {
      return date;
    }

    if (date.match(/^\d{4}-[01]\d-[0-3]\d [0-2]\d:[0-5]\d:[0-5]\d$/) != null) {
      date = date.replace(/-/g, '/');
    }
    date = new Date(Date.parse(date));

    format = format.replace(/%Y/g, date.getFullYear());
    format = format.replace(/%m/g, ((date.getMonth() + 1)));
    format = format.replace(/%d/g, (date.getDate()));
    format = format.replace(/%H/g, (date.getHours()));
    format = format.replace(/%M/g, ('0' + date.getMinutes()).slice(-2));
    format = format.replace(/%S/g, ('0' + date.getSeconds()).slice(-2));
    format = format.replace(/%L/g, ('00' + date.getMilliseconds()).slice(-3));
    return format;
  }

  function getByteLength(str) {
    return encodeURIComponent(str).replace(/%../g, 'x').length;
  }

  function load_widget_features(){
console.log("load_widget_features start")

    if (conf.isDevelop !== true) {
      // set carousel
      require('bootstrap/js/carousel')
      require('bootstrap/js/transition')


      if (conf.project == 'misumi-faq'){
      }
      else {
        // set draggable
        require('jquery-ui/ui/widgets/draggable')
        jQuery('#'+tmpl.container_id).draggable({
          handle: $(`#${tmpl.header_id}`),
          containment: 'window',
          disable: 'window',
          stop: function(event,ui){
            localStorage.setItem('container-position-left', jQuery('#'+tmpl.container_id).css('left'))
            localStorage.setItem('container-position-top' , jQuery('#'+tmpl.container_id).css('top'))
          }
        })
      }
    }
    else { 
      // set carousel
      jQuery.getScript(conf.chatwidget_plugin_js_carousel_url);

      if (conf.project == 'misumi-faq'){
      }
      else {
        // set draggable
        jQuery.getScript(conf.jquery_ui_url, function () {
          jQuery('#'+tmpl.container_id).draggable({
            handle: $(`#${tmpl.header_id}`),
            containment: 'window',
            stop: function(event,ui){
              localStorage.setItem('container-position-left', jQuery('#'+tmpl.container_id).css('left'))
              localStorage.setItem('container-position-top' , jQuery('#'+tmpl.container_id).css('top'))
            }
          })
        })
      }

    }

/*
if (!(['misumi-faq'].indexOf(conf.project)+1 > 0)) {
  jQuery('#'+tmpl.container_id).draggable('disable')
}
*/

    // set glyphicon
    jQuery('body').append( jQuery('<link>').attr({
      'rel': 'stylesheet',
      'href': conf.chatwidget_plugin_css_glyphicon_url,
    }))
    stylesheet.insertRule(`
      .glyphicon {
        font-family: 'Glyphicons Halflings' !important
      }
    `, stylesheet.cssRules.length );

    // set carousel
    jQuery('body').append( jQuery('<link>').attr({
      'rel': 'stylesheet',
      'href': conf.chatwidget_plugin_css_carousel_url,
    }))
    jQuery('body').append( jQuery('<link>').attr({
      'rel': 'stylesheet',
      'href': conf.chatwidget_plugin_css_carousel_style_url,
    }))

    console.log("load_widget_features end")

  }

  function resize_widget() {
    let inner_control_height;
    let adjust_height;
    let computed_body_height;
    let adjust_width;
    let computed_widget_width;

    switch (tmpl.theme_name) {
      case 'misumi-faq':
        inner_control_height = Math.max(jQuery(`#${tmpl.send_msg_button_id}`).outerHeight(), jQuery(`#${tmpl.send_msg_text_id}`).outerHeight())
        adjust_height = Math.floor(jQuery(`#${tmpl.footer_id}`).outerHeight() + inner_control_height) + 1
        computed_body_height = Math.floor(
          jQuery(`#${tmpl.container_id}`).outerHeight() 
            - jQuery(`#${tmpl.header_id}`).outerHeight() 
            - jQuery(`#${tmpl.footer_id}`).outerHeight()
            - adjust_height
          )

        if (conf.isDebug === true) { console.debug('computed_body_height is ',computed_body_height) }
        stylesheet.insertRule(`
          #${tmpl.body_id} {
            height: ${computed_body_height}px !important;
            min-height: ${computed_body_height}px !important;
          }
        `, stylesheet.cssRules.length );

        adjust_width = 100
        computed_widget_width = Math.floor(jQuery(`#${tmpl.container_id}`).outerWidth()) - adjust_width
        if (conf.isDebug === true) { console.debug('computed_widget_width is ',computed_widget_width) }
        stylesheet.insertRule(`
          #${tmpl.body_id} .${tmpl.class_balloon} {
            max-width: ${computed_widget_width}px !important;
          }
        `, stylesheet.cssRules.length );
        break;

      case 'default':
      default:
        inner_control_height = Math.max(jQuery(`#${tmpl.send_msg_button_id}`).outerHeight(), jQuery(`#${tmpl.send_msg_text_id}`).outerHeight())
        adjust_height = Math.floor(jQuery(`#${tmpl.footer_id}`).outerHeight() + inner_control_height) + 1
        computed_body_height = Math.floor(
          jQuery(`#${tmpl.container_id}`).outerHeight() 
            - jQuery(`#${tmpl.header_id}`).outerHeight() 
            - jQuery(`#${tmpl.footer_id}`).outerHeight()
            - parseInt(jQuery(`#${tmpl.container_id}`).css('padding-top'))
            - parseInt(jQuery(`#${tmpl.container_id}`).css('padding-bottom'))
            // - adjust_height
          )
        $(`#${tmpl.body_id}`).css('height',`${computed_body_height}px`)
        break;

    }
  }

});
