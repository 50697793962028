import * as webchat_app from './webchat-client.js';
import * as bcwu from './function.js';


// function show_default_load_ui(tmpl){
//   console.debug('show_default_load_ui(tmpl), ', tmpl.default_load_ui)
//   if (tmpl.default_load_ui == 'eyecatch-image') {
//     $(`#${tmpl.container_id}`).hide();
//     $(`#${tmpl.chatwidget_eyecatch_id}`).show();
//     console.debug('show eyecatch image')
//   }
//   if (tmpl.default_load_ui == 'widget'){
//     $(`#${tmpl.chatwidget_eyecatch_id}`).hide();
//     $(`#${tmpl.container_id}`).show();
//     console.debug('show chatwidget')
//   }
// }

export async function load_settings(json) {
  let tmpl = json.template
  let conf = json.config;
  let stylesheet = json.stylesheet;
  let return_json = {};

  const common_master_settings = await webchat_app.get_common_master_settings({config: conf});
  if (conf.isDebug === true) { console.debug(common_master_settings) }

  let key,value;

  key = 'WEBCHAT_WIDGET_EYECATCH_IMAGE'
  if (bcwu.isPresent(common_master_settings[key])) { value = common_master_settings[key]
    conf.chatwidget_eyecatch_url = value;
    jQuery(`#${tmpl.chatwidget_eyecatch_id} img`).attr('src',value);
//    jQuery(`#${tmpl.chatwidget_eyecatch_id} img`).on('load',function(){
//      show_default_load_ui(tmpl)
//    })
//  }
//  else {
//    show_default_load_ui(tmpl)
  }

  // key = 'WEBCHAT_INIT_MESSAGE_KEY'
  // if (bcwu.isPresent(common_master_settings[key])) { value = common_master_settings[key]
  // }

  // key = 'WEBCHAT_BROWSER_TITLE_NAME'
  // if (bcwu.isPresent(common_master_settings[key])) { value = common_master_settings[key]
  // }

  key = 'WEBCHAT_HEADER_LOGO_IMAGE'
  if (bcwu.isPresent(common_master_settings[key])) { value = common_master_settings[key]
    jQuery(`#${tmpl.header_icon_id}`).attr('src', value)
  }

  key = 'WEBCHAT_HEADER_TITLE'
  if (bcwu.isPresent(common_master_settings[key])) { value = common_master_settings[key]
    jQuery(`#${tmpl.header_title_id}`).text(value)
  }

  key = 'WEBCHAT_HEADER_DESCRIPTION'
  if (bcwu.isPresent(common_master_settings[key])) { value = common_master_settings[key]
    jQuery(`#${tmpl.header_description_id}`).text(value)
  }

  // key = 'WEBCHAT_HEADER_MENU1_BTN_DISP'
  // if (bcwu.isPresent(common_master_settings[key])) { value = common_master_settings[key]
  // }

  // key = 'WEBCHAT_HEADER_MENU1_BTN_ICON'
  // if (bcwu.isPresent(common_master_settings[key])) { value = common_master_settings[key]
  // }

  // key = 'WEBCHAT_HEADER_MENU1_TITLE'
  // if (bcwu.isPresent(common_master_settings[key])) { value = common_master_settings[key]
  // }

  // key = 'WEBCHAT_HEADER_MENU1_BODY'
  // if (bcwu.isPresent(common_master_settings[key])) { value = common_master_settings[key]
  // }

  // key = 'WEBCHAT_HEADER_MENU2_BTN_DISP'
  // if (bcwu.isPresent(common_master_settings[key])) { value = common_master_settings[key]
  // }

  // key = 'WEBCHAT_HEADER_MENU2_BTN_ICON'
  // if (bcwu.isPresent(common_master_settings[key])) { value = common_master_settings[key]
  // }

  // key = 'WEBCHAT_HEADER_MENU2_TITLE'
  // if (bcwu.isPresent(common_master_settings[key])) { value = common_master_settings[key]
  // }

  // key = 'WEBCHAT_HEADER_MENU2_BODY'
  // if (bcwu.isPresent(common_master_settings[key])) { value = common_master_settings[key]
  // }

  // key = 'WEBCHAT_HEADER_MENU3_BTN_DISP'
  // if (bcwu.isPresent(common_master_settings[key])) { value = common_master_settings[key]
  // }

  // key = 'WEBCHAT_HEADER_MENU3_BTN_ICON'
  // if (bcwu.isPresent(common_master_settings[key])) { value = common_master_settings[key]
  // }

  // key = 'WEBCHAT_HEADER_MENU3_TITLE'
  // if (bcwu.isPresent(common_master_settings[key])) { value = common_master_settings[key]
  // }

  // key = 'WEBCHAT_HEADER_MENU3_BODY'
  // if (bcwu.isPresent(common_master_settings[key])) { value = common_master_settings[key]
  // }

  key = 'WEBCHAT_HEADER_BGCOLOR' //notice
  if (bcwu.isPresent(common_master_settings[key])) { value = common_master_settings[key]
    stylesheet.insertRule(`
      #${tmpl.header_id} {
        background-color: ${value} !important;
      }
    `, stylesheet.cssRules.length );

    switch (tmpl.theme_name) {
      case 'misumi-faq':
        stylesheet.insertRule(`
        #${tmpl.header_id} {
          background: linear-gradient(to bottom, ${value} 54%, rgba(255,255,255,1) 54%) !important;
        }
        `, stylesheet.cssRules.length );
        break;

      case 'default':
        stylesheet.insertRule(`
        #${tmpl.container_id} {
          background-color: ${value} !important;
        }
        `, stylesheet.cssRules.length );
        break;

      default:
        break;
    }
    
  }

  // header title text color
  key = 'WEBCHAT_HEADER_TEXT_COLOR'
  if (bcwu.isPresent(common_master_settings[key])) { value = common_master_settings[key]
    stylesheet.insertRule(`
      #${tmpl.header_title_id} {
        color: ${value} !important;
      }
    `, stylesheet.cssRules.length );

    //description_id
  }

  key = 'WEBCHAT_MAIN_BGCOLOR'
  if (bcwu.isPresent(common_master_settings[key])) { value = common_master_settings[key]
    stylesheet.insertRule(`
      #${tmpl.body_id},
      ${tmpl.selector_balloon_after} {
        background-color: ${value} !important;
      }
    `, stylesheet.cssRules.length );
  }

  key = 'WEBCHAT_MAIN_TEXT_COLOR'
  if (bcwu.isPresent(common_master_settings[key])) { value = common_master_settings[key]
    stylesheet.insertRule(`
      #${tmpl.body_id},
      ${tmpl.selector_time_disp} {
        color: ${value} !important;
      }
    `, stylesheet.cssRules.length );
  }

  key = 'WEBCHAT_PANEL_BORDER_COLOR' //notice
  if (bcwu.isPresent(common_master_settings[key])) { value = common_master_settings[key]
    stylesheet.insertRule(`
      #${tmpl.container_id},
      #${tmpl.header_id},
      #${tmpl.body_id},
      #${tmpl.footer_id} {
        border-color: ${value} !important;
      }
    `, stylesheet.cssRules.length );
  }

  key = 'WEBCHAT_USER_BALLOON_COLOR' //add important
  if (bcwu.isPresent(common_master_settings[key])) { value = common_master_settings[key]
    stylesheet.insertRule(`
      ${tmpl.selector_user_balloon_before}, 
      .${tmpl.class_user_message} .${tmpl.class_balloon} {
        background-color: ${value} !important;
      }
    `, stylesheet.cssRules.length );
  }

  key = 'WEBCHAT_USER_BALLOON_TEXT_COLOR'
  if (bcwu.isPresent(common_master_settings[key])) { value = common_master_settings[key]
    stylesheet.insertRule(`
      .${tmpl.class_user_message} .${tmpl.class_balloon} {
        color: ${value} !important;
      }
    `, stylesheet.cssRules.length );
  }

  key = 'WEBCHAT_USER_BALLOON_LINK_COLOR'
  if (bcwu.isPresent(common_master_settings[key])) { value = common_master_settings[key]
    stylesheet.insertRule(`
      .${tmpl.class_user_message} .${tmpl.class_balloon} a {
        color: ${value} !important;
      }
    `, stylesheet.cssRules.length );
  }

  key = 'WEBCHAT_BOT_BALLOON_COLOR'
  if (bcwu.isPresent(common_master_settings[key])) { value = common_master_settings[key]
    stylesheet.insertRule(`
      .${tmpl.class_bot_message} .${tmpl.class_balloon} {
        background-color: ${value} !important;
      }
    `, stylesheet.cssRules.length );
    stylesheet.insertRule(`
      ${tmpl.selector_bot_balloon_before} {
        background-color: ${value} !important;
      }
    `, stylesheet.cssRules.length );
  }

  key = 'WEBCHAT_BOT_BALLOON_TEXT_COLOR'
  if (bcwu.isPresent(common_master_settings[key])) { value = common_master_settings[key]
    stylesheet.insertRule(`
      .${tmpl.class_bot_message} .${tmpl.class_balloon} {
        color: ${value} !important;
      }
    `, stylesheet.cssRules.length );
  }

  key = 'WEBCHAT_BOT_BALLOON_LINK_COLOR'
  if (bcwu.isPresent(common_master_settings[key])) { value = common_master_settings[key]
    stylesheet.insertRule(`
      .${tmpl.class_bot_message} .${tmpl.class_balloon} a {
        color: ${value} !important;
      }
    `, stylesheet.cssRules.length );
  }

  key = 'WEBCHAT_BOT_ICON_IMAGE'
  if (bcwu.isPresent(common_master_settings[key])) { value = common_master_settings[key]
    switch (tmpl.theme_name) {
      case 'misumi-faq':
        stylesheet.insertRule(`
          .${tmpl.class_bot_message} .bot-img {
            background-image: url('${value}') !important;
          }
        `, stylesheet.cssRules.length );
        break;

      case 'default':
        conf.chatwidget_bot_icon_url = value;
        jQuery(`${tmpl.selector_dom_bot_img} img`).attr('src',value);
        console.debug('${tmpl.selector_dom_bot_img} img:',`${tmpl.selector_dom_bot_img} img`)
        jQuery(`${tmpl.selector_dom_bot_img} img`).on('load',function(){
          // $(`#${tmpl.chatwidget_eyecatch_id}`).hide();
          // $(`#${tmpl.container_id}`).show();
        })    
        break;
    
      default:
        break;
    }
  }

  key = 'WEBCHAT_DATE_BGCOLOR'
  if (bcwu.isPresent(common_master_settings[key])) { value = common_master_settings[key]
    stylesheet.insertRule(`
      ${tmpl.selector_date_disp} ${tmpl.selector_date_disp_inner} {
        background-color: ${value} !important
      }
    `, stylesheet.cssRules.length );
  }

  key = 'WEBCHAT_DATE_TEXT_COLOR'
  if (bcwu.isPresent(common_master_settings[key])) { value = common_master_settings[key]
    stylesheet.insertRule(`
      ${tmpl.selector_date_disp} ${tmpl.selector_date_disp_inner} {
        color: ${value} !important
      }
    `, stylesheet.cssRules.length );
  }

  key = 'WEBCHAT_TEXT_PLACEHOLDER'
  if (bcwu.isPresent(common_master_settings[key])) { value = common_master_settings[key]
    jQuery(`#${tmpl.send_msg_text_id}`).attr('placeholder',value)
  }

  // submit button styles
  key = 'WEBCHAT_SUBMIT_VALUE'
  if (bcwu.isPresent(common_master_settings[key])) { value = common_master_settings[key]

    switch (tmpl.theme_name) {
      case 'misumi-faq':
        jQuery(`#${tmpl.send_msg_button_id}`).val(value);
        break;

      case 'default':
        jQuery(`#${tmpl.send_msg_button_id}`).text(value);
        break;

      default:
        break;
    }

  }

  key = 'WEBCHAT_SUBMIT_BGCOLOR'
  if (bcwu.isPresent(common_master_settings[key])) { value = common_master_settings[key]
    jQuery(`#${tmpl.send_msg_button_id}`).css('background-color',value)
  }

  key = 'WEBCHAT_SUBMIT_TEXT_COLOR'
  if (bcwu.isPresent(common_master_settings[key])) { value = common_master_settings[key]
    jQuery(`#${tmpl.send_msg_button_id}`).css('color',value)
  }

  key = 'WEBCHAT_SUBMIT_FONT_TYPE'
  if (bcwu.isPresent(common_master_settings[key])) { value = common_master_settings[key]
    jQuery(`#${tmpl.send_msg_button_id}`).css('font-family',value)
  }

  key = 'WEBCHAT_SUBMIT_FONT_SIZE'
  if (bcwu.isPresent(common_master_settings[key])) { value = common_master_settings[key]
    jQuery(`#${tmpl.send_msg_button_id}`).css('font-size',value)
  }

  // header title font family
  key = 'WEBCHAT_HEADER_FONT_TYPE'
  if (bcwu.isPresent(common_master_settings[key])) { value = common_master_settings[key]
    jQuery(`#${tmpl.header_title_id}`).css('font-family',value)
  }

  // header title font size
  key = 'WEBCHAT_HEADER_FONT_SIZE'
  if (bcwu.isPresent(common_master_settings[key])) { value = common_master_settings[key]
    jQuery(`#${tmpl.header_title_id}`).css('font-size',value)
  }

  // body time font family
  key = 'WEBCHAT_MAIN_FONT_TYPE'
  if (bcwu.isPresent(common_master_settings[key])) { value = common_master_settings[key]
    stylesheet.insertRule(`
      #${tmpl.body_id},
      ${tmpl.selector_time_disp} {
        font-family: ${value} !important;
      }
    `, stylesheet.cssRules.length );
  }

  // body time font size
  key = 'WEBCHAT_MAIN_FONT_SIZE'
  if (bcwu.isPresent(common_master_settings[key])) { value = common_master_settings[key]
    stylesheet.insertRule(`
      #${tmpl.body_id},
      ${tmpl.selector_time_disp} {
        font-size: ${value} !important;
      }
    `, stylesheet.cssRules.length );
  }

  key = 'WEBCHAT_USER_BALLOON_FONT_TYPE'
  if (bcwu.isPresent(common_master_settings[key])) { value = common_master_settings[key]
    stylesheet.insertRule(`
      .${tmpl.class_user_message} .${tmpl.class_balloon},
      .${tmpl.class_user_message} .${tmpl.class_balloon} :not(${tmpl.element_balloon_button}):not(.glyphicon):not(.carousel-control) {
        font-family: ${value} !important;
      }
    `, stylesheet.cssRules.length );
  }

  key = 'WEBCHAT_USER_BALLOON_FONT_SIZE'
  if (bcwu.isPresent(common_master_settings[key])) { value = common_master_settings[key]
    stylesheet.insertRule(`
      .${tmpl.class_user_message} .${tmpl.class_balloon},
      .${tmpl.class_user_message} .${tmpl.class_balloon} :not(${tmpl.element_balloon_button}):not(.glyphicon):not(.carousel-control) {
        font-size: ${value} !important;
      }
    `, stylesheet.cssRules.length );
  }

  key = 'WEBCHAT_BOT_BALLOON_FONT_TYPE'
  if (bcwu.isPresent(common_master_settings[key])) { value = common_master_settings[key]
    stylesheet.insertRule(`
      .${tmpl.class_bot_message} .${tmpl.class_balloon},
      .${tmpl.class_bot_message} .${tmpl.class_balloon} :not(${tmpl.element_balloon_button}):not(.glyphicon):not(.carousel-control) {
        font-family: ${value} !important;
      }
    `, stylesheet.cssRules.length );
  }

  key = 'WEBCHAT_BOT_BALLOON_FONT_SIZE'
  if (bcwu.isPresent(common_master_settings[key])) { value = common_master_settings[key]
    stylesheet.insertRule(`
      .${tmpl.class_bot_message} .${tmpl.class_balloon},
      .${tmpl.class_bot_message} .${tmpl.class_balloon} :not(${tmpl.element_balloon_button}):not(.glyphicon):not(.carousel-control) {
        font-size: ${value} !important;
      }
    `, stylesheet.cssRules.length );
  }

  key = 'WEBCHAT_DATE_FONT_TYPE'
  if (bcwu.isPresent(common_master_settings[key])) { value = common_master_settings[key]
    stylesheet.insertRule(`
      ${tmpl.selector_date_disp} ${tmpl.selector_date_disp_inner} {
        font-family: ${value} !important
      }
    `, stylesheet.cssRules.length );
  }

  key = 'WEBCHAT_DATE_FONT_SIZE'
  if (bcwu.isPresent(common_master_settings[key])) { value = common_master_settings[key]
    stylesheet.insertRule(`
      ${tmpl.selector_date_disp} ${tmpl.selector_date_disp_inner} {
        font-size: ${value} !important
      }
    `, stylesheet.cssRules.length );
  }

  key = 'WEBCHAT_BALLOON_BUTTON_ALIGN'
  if (bcwu.isPresent(common_master_settings[key])) { value = common_master_settings[key]
    stylesheet.insertRule(`
      ${tmpl.selector_listtype_message_button} {
        text-align: ${value} !important;
      }
    `, stylesheet.cssRules.length );
    // if (conf.isDebug === true) { console.debug('WEBCHAT_BALLOON_BUTTON_ALIGN',value) }
  }
  
  key = 'WEBCHAT_BALLOON_BUTTON_COLOR'
  if (bcwu.isPresent(common_master_settings[key])) { value = common_master_settings[key]
    stylesheet.insertRule(`
      ${tmpl.selector_balloon_button} {
        background-color: ${value} !important;
        border-color: ${value} !important;
      }
    `, stylesheet.cssRules.length );
  }

  key = 'WEBCHAT_BALLOON_BUTTON_SIZE'
  if (bcwu.isPresent(common_master_settings[key])) { value = common_master_settings[key]
    //notice: 
    stylesheet.insertRule(`
      ${tmpl.selector_listtype_message_button} {
        width: ${value} !important;
      }
    `, stylesheet.cssRules.length );
  }

  key = 'WEBCHAT_BALLOON_BUTTON_TEXT_COLOR'
  if (bcwu.isPresent(common_master_settings[key])) { value = common_master_settings[key]
    stylesheet.insertRule(`
      ${tmpl.selector_balloon_button} {
        color: ${value} !important;
      }
    `, stylesheet.cssRules.length );
  }

  key = 'WEBCHAT_BALLOON_BUTTON_FONT_TYPE'
  if (bcwu.isPresent(common_master_settings[key])) { value = common_master_settings[key]
    stylesheet.insertRule(`
      ${tmpl.selector_balloon_button} {
        font-family: ${value} !important;
      }
    `, stylesheet.cssRules.length );
  }

  key = 'WEBCHAT_BALLOON_BUTTON_FONT_SIZE'
  if (bcwu.isPresent(common_master_settings[key])) { value = common_master_settings[key]
    stylesheet.insertRule(`
      ${tmpl.selector_balloon_button} {
        font-size: ${value} !important;
      }
    `, stylesheet.cssRules.length );
  }

  key = 'WEBCHAT_EMBEDDED_HEIGHT'
  if (bcwu.isPresent(common_master_settings[key])) { value = common_master_settings[key]
/*
    stylesheet.insertRule(`
      #${tmpl.container_id} {
        height: ${value} !important;
      }
    `, stylesheet.cssRules.length );
*/
    jQuery(`#${tmpl.container_id}`).outerHeight(value)
//    jQuery(`#${tmpl.body_id}`).outerHeight( jQuery(`#${tmpl.container_id}`).outerHeight() - jQuery(`#${tmpl.header_id}`).outerHeight() - ( jQuery(`#${tmpl.footer_id}`).outerHeight() * 2 ) -5 )
  }

  key = 'WEBCHAT_EMBEDDED_WIDTH'
  if (bcwu.isPresent(common_master_settings[key])) { value = common_master_settings[key]
    stylesheet.insertRule(`
      #${tmpl.container_id} {
        width: ${value} !important;
      }
    `, stylesheet.cssRules.length );
  }

  // key = 'WEBCHAT_HEADER_BOT_ICON_IMAGE'
  // if (bcwu.isPresent(common_master_settings[key])) { value = common_master_settings[key]
  // }

  // key = 'WEBCHAT_FOOTER_IMAGE'
  // if (bcwu.isPresent(common_master_settings[key])) { value = common_master_settings[key]
  // }

  // key = 'WEBCHAT_FOOTER_IMAGE_AREA'
  // if (bcwu.isPresent(common_master_settings[key])) { value = common_master_settings[key]
  // }

  // key = 'WEBCHAT_HISTORY_DISP'
  // if (bcwu.isPresent(common_master_settings[key])) { value = common_master_settings[key]
  // }

  // key = 'WEBCHAT_COOKIE_EXPIRE'
  // if (bcwu.isPresent(common_master_settings[key])) { value = common_master_settings[key]
  // }

  // key = 'WEBCHAT_INIT_HISTORY_NUM'
  // if (bcwu.isPresent(common_master_settings[key])) { value = common_master_settings[key]
  // }

  // key = 'WEBCHAT_HISTORY_PERIOD'
  // if (bcwu.isPresent(common_master_settings[key])) { value = common_master_settings[key]
  // }

  key = 'POSTBACK_BUTTON_RE_SELECT'
  if (bcwu.isPresent(common_master_settings[key])) { value = common_master_settings[key]
    if (value.match(/true/i)) { conf.button_resel = true };
  }
  if (conf.button_resel !== true) { conf.button_resel = false};


  key = 'WEBCHAT_LINK_NEW_WINDOW'
  if (bcwu.isPresent(common_master_settings[key])) { value = common_master_settings[key]
  }

  key = 'WEBCHAT_LINK_NEW_WINDOW_WIDTH'
  if (bcwu.isPresent(common_master_settings[key])) { value = common_master_settings[key]
  }

  key = 'WEBCHAT_LINK_NEW_WINDOW_HEIGHT'
  if (bcwu.isPresent(common_master_settings[key])) { value = common_master_settings[key]
  }

  key = 'WEBCHAT_IMAGE_LINK_MODAL'
  if (bcwu.isPresent(common_master_settings[key])) { value = common_master_settings[key]
  }

  return_json = {
    template: tmpl,
    config: conf,
    stylesheet: stylesheet,
  }

  return return_json;
}
