'use strict'

export function define_conf(conf) {
  conf.benefitter_bootstrap_css_url = `${conf.widget_root}/theme/default/css/bootstrap-benefitter-chat-widget.css`

  conf.benefitter_inside_css_url  = `${conf.widget_root}/theme/default/css/webchat-emb-inner-style.css`
  conf.benefitter_outside_css_url = `${conf.widget_root}/theme/default/css/webchat-emb-outer-frame.css`

  conf.chatwidget_eyecatch_url = `${conf.widget_root}/theme/default/images/brownie/webchat/kochira.png`
  conf.chatwidget_bot_icon_url = `${conf.widget_root}/theme/default/images/brownie/webchat/bot-01.png`

  return conf;
}

export function define_view(conf) {
  let template = {};

  template.theme_name = 'default'

  template.chatwidget_eyecatch_id = 'chatwidget_eyecatch'
  template.chatwidget_eyecatch = `
    <div id="${template.chatwidget_eyecatch_id}" style="display:none">
      <img src="${conf.chatwidget_eyecatch_url}">
    </div>
    `;

  template.container_id = 'webchat-container'

  template.container = `
    <div id="${template.container_id}" style="display:none">
    </div>
    `;

  // widget header definition

  template.header_id = 'panel-heading'
  template.webchat_close_button_id = 'webchat-close-button'
  template.webchat_home_button_id = 'webchat-home-button'
  template.header_title_id = 'header-title'
  template.header_description_id = 'header-description'
  template.header_icon_id = 'header-icon'

  template.header = `
    <div class="panel-heading" id="${template.header_id}">
      <div class="header-bar">
        <div class="header-right">
          <div class="header-home">
            <div class="col-xs-4lessp" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="ホームへ戻る">
              <button type="button" class="nav-helpbutton btn btn-default" id="${template.webchat_home_button_id}">
                <span class="glyphicon glyphicon-home"></span>
              </button>
            </div>
          </div>
          <div class="header-close">
            <div class="col-xs-4lessp" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="閉じる">
              <button type="button" class="nav-helpbutton btn btn-default" id="${template.webchat_close_button_id}">
                <span class="glyphicon glyphicon-remove"></span>
              </button>
            </div>
          </div>
        </div>
        <span class="header-img-group">
          <img id="${template.header_icon_id}" src="">
        </span>
        <div class="header-left">
          <div class="header-title" id="${template.header_title_id}"></div>
          <div class="header-description" id="${template.header_description_id}"></div>
        </div>
      </div>
    </div>
  `;

  
  // widget body definition

  template.selector_date_disp = 'div.date_disp'
  template.selector_date_disp_inner = 'div.date_disp_inner'
  
  template.selector_chatwidget_body = '.panel-body'
  template.selector_chatwidget_transaction = 'section.sec-current'

  template.class_bot_message = 'from-them_outer'
  template.class_user_message = 'from-me_outer'

  template.class_balloon = 'balloon'
  template.class_bot_balloon = 'from-them'
  template.class_user_balloon = 'from-me'

  template.selector_user_balloon = `${template.selector_chatwidget_transaction} > .${template.class_user_message} > .${template.class_user_balloon}`
  template.selector_bot_balloon = `${template.selector_chatwidget_transaction} > .${template.class_bot_message} > .${template.class_bot_balloon}`
  template.selector_user_balloon_before = `${template.selector_chatwidget_transaction} .${template.class_user_message} .${template.class_balloon}:before`
  template.selector_bot_balloon_before = `${template.selector_chatwidget_transaction} .${template.class_bot_message} .${template.class_balloon}:before`

  template.element_balloon_button = `button`
  template.selector_balloon_button = `.${template.class_bot_balloon} ${template.element_balloon_button}`
  template.selector_listtype_message_button = `.${template.class_bot_balloon} .question-block ${template.element_balloon_button}`

  template.selector_balloon_after = `${template.selector_chatwidget_transaction} .${template.class_balloon}:after`

  //do_scroll
  template.selector_dom_bot_img = `.${template.class_bot_message} > .chat-img`
  template.selector_dom_chatwidget_balloon = `.${template.class_bot_message} > .${template.class_balloon}`
  //var $dom_bot_img = $(template.selector_chatwidget_body).find(".chat-img.pull-left:last");
  //var $dom_comment = $(template.selector_chatwidget_body).find(".from-them:last");



  template.selector_button_inside_balloon = `.${template.class_bot_balloon} ${template.class_balloon} button`

  template.selector_time_disp = `${template.selector_chatwidget_transaction} > [class$="-_outer"] > .time_disp`


  template.body_id = 'webchat-content-body'
  template.body = `
    <div class="row">
      <div id="${template.body_id}" class="panel-body">
        <section class="sec-history"></section>
        <section class="sec-current"></section>
      </div>
    </div>
  `;

  // widget footer definition

  template.send_msg_button_id = 'send_msg_button'
  template.send_msg_text_id = 'send_msg_text'

  template.footer_id = 'webchat-footer'
  template.footer = `
    <div class="row"><div id="${template.footer_id}" class="panel-footer">
      <div class="input-group">
          <input id="${template.send_msg_text_id}" type="text" class="form-control input-sm" maxlength="400" placeholder="" data-link-new-window-flg="" data-link-new-window-width="" data-link-new-window-height="" data-image-link-modal-flg="" data-file_link_enabled_flg="">
          <span class="input-group-btn">
            <button id="${template.send_msg_button_id}" class="btn btn-primary btn-sm">送信</button>
          </span>
        </div>
    </div></div>
    `;
  
  return template;
}

export function generate_tag(ca, va, opt, id) {
  let conf = define_conf({});

  var ret = "";
  switch (ca){
    case "d": // date
      ret =   '<div class="date_disp"><div class="date_disp_inner">' + va + '</div></div>';
      break;
    case "t": // time
      ret = '<div class="time_disp">' + va + '</div>';
      break;
    case "i": // icon image
      //ret = '<span class="chat-img pull-left"><img src="bot_icon.jpg" alt="Bot" class="img-circle"></span>';
      //ret = '<span class="chat-img pull-left"><img src="<%= image_path('brownie/webchat/bot_icon.jpg') %>" alt="Bot" class="img-circle"></span>';
      //var bot_icon_img = $('#bot-image').data('bot-image-id');
      if (va != '' && va != null && va != undefined) { 
        var bot_icon_img = va; //debug
      }else{
        var bot_icon_img = conf.chatwidget_bot_icon_url; //debug
      } 
      if (conf.isDebug === true) { console.debug(bot_icon_img) }
      ret = '<span class="chat-img pull-left"><img src="' + bot_icon_img + '" alt="Bot" class="img-circle"></span>';
      break;
    case "crs": // carousel
      ret = ['<div id="carousel-area' + id + '" class="carousel slide" data-interval="false">',
            '  <!-- indicator  -->',
            '  <ol class="carousel-indicators">',
            opt,
            '  </ol>',
            '  <!-- images -->',
            '  <div class="carousel-inner">',
            va,
            '  </div>',
            '  <!-- slider button : left/right -->',
            '  <a class="left carousel-control" href="#carousel-area' + id + '" data-slide="prev">',
            '    <span class="glyphicon glyphicon-chevron-left" aria-hidden="true"></span>',
            '  </a>',
            '  <a class="right carousel-control" href="#carousel-area' + id + '" data-slide="next">',
            '    <span class="glyphicon glyphicon-chevron-right" aria-hidden="true"></span>',
            '  </a>',
            '</div>'].join("");
      break;
  }
  return ret;
}

export function add_styles(params_json){
  let stylesheet = params_json.stylesheet;
  let conf = params_json.config;
  let tmpl = params_json.template;

  stylesheet.insertRule('#'+tmpl.container_id+' {' +
    'border-radius: 7px 7px 3px 3px;' +
  '}', stylesheet.cssRules.length );


  //metaタグにおいてviewportが指定されていない場合に追加
  if ( !(jQuery('meta[name="viewport"]').length > 0 ) ) {
    jQuery('head').append('<meta name="viewport" content="width=device-width">')
  }

  //benefitter inside style
  jQuery('body').append( jQuery('<link>').attr({
    'rel': 'stylesheet',
    'href': conf.benefitter_bootstrap_css_url,
  }))

  //benefitter inside style
  jQuery('body').append( jQuery('<link>').attr({
    'rel': 'stylesheet',
    'href': conf.benefitter_inside_css_url,
  }))

  //benefitter outside style
  jQuery('body').append( jQuery('<link>').attr({
    'rel': 'stylesheet',
    'href': conf.benefitter_outside_css_url,
  }))


  let header_height = 50
  let body_height = 500
  let footer_height = 50
  let container_height = header_height + body_height + footer_height

  stylesheet.insertRule(`
    #${tmpl.container_id} {
      height: ${container_height}px;
    }
  `, stylesheet.cssRules.length );
  stylesheet.insertRule(`
    #${tmpl.header_id} {
      height: ${header_height}px;
    }
  `, stylesheet.cssRules.length );
  stylesheet.insertRule(`
    #${tmpl.body_id} {
      height: ${body_height}px;
    }
  `, stylesheet.cssRules.length );
  stylesheet.insertRule(`
    #${tmpl.footer_id} {
      height: ${footer_height}px;
    }
  `, stylesheet.cssRules.length );  

}
